import styles from './AuthInput.module.css'

export function AuthInput( { type, placeholder, onChange, value } ) {
    return (
        <input
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={styles.authInput}
        />
    )
}