import styles from '../Button.module.css'
import {Link} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";

export function Button( {text, onClick, backgroundColor, icon, link, fontSize, isLoading} ) {
    return (
        <Link to={link} onClick={!isLoading && onClick}>
            <div className={` ${styles.buttonContainer} ${isLoading && styles.disabledButton} `}
                 style={{ backgroundColor: backgroundColor, padding: text ? '10px 18px' : '10px' }}>
                {text && <p style={{ fontSize: fontSize ? fontSize : "17px" }}>{text}</p>}
                {isLoading ? <LoadingOutlined style={{fontSize: fontSize}} spin/> : icon}
                <div className={styles.overlay}></div>
            </div>
        </Link>
    )
}
