import {axiosInstance} from "../axiosInstance";

export const cadastrarNovoAfiliado = async (afiliadoData) => {
    const response = await axiosInstance.post("/afiliados/cadastro", afiliadoData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    return response.data
}

export const cadastrarEnderecoAfiliado = async (enderecoData, id) => {
    const response = await axiosInstance.post(`/afiliados/${id}/endereco`, enderecoData)
    return response.data
}

export const cadastrarDadosMedicosAfiliado = async (dadosMedicosData, id) => {
    const response = await axiosInstance.post(`/afiliados/${id}/dados-medicos`, dadosMedicosData)
    return response.data
}

export const cadastrarDadosDemograficosAfiliado = async (dadosDemograficosData, id) => {
    const response = await axiosInstance.post(`/afiliados/${id}/dados-demograficos`, dadosDemograficosData)
    return response.data
}

export const cadastrarInformacaoAfiliado = async (informacaoData, id) => {
    const response = await axiosInstance.post(`/afiliados/${id}/historico-informacoes`, informacaoData)
    return response.data
}

export const cadastrarContatoEmergenciaAfiliado = async (contatoData, id) => {
    const response = await axiosInstance.post(`/afiliados/${id}/contatos-emergencia`, contatoData)
    return response.data
}

export const uploadDocumentoAfiliado = async (documento, id) => {
    const response = await axiosInstance.post(`/afiliados/${id}/documentos`, documento,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}








