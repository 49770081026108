import styles from "../DadosPessoaisAfiliado.module.css";
import React, {useContext, useId, useState} from "react";
import {Button} from "../../CustomButtons/Button/Button";
import {IoCheckmarkCircleSharp} from "react-icons/io5";
import {InputMask} from "primereact/inputmask";
import {editarAfiliado} from "../../../services/apiService/editarAfiliadoService";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";
import {useNavigate} from "react-router-dom";

export function EditarDadosPessoais({ dadosPessoais }) {
    const { id } = useContext(AfiliadoContext);
    const [erros, setErros] = useState([]);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        nome: dadosPessoais.nome,
        email: dadosPessoais.email,
        dataNascimento: dadosPessoais.dataNascimento,
        cpf: dadosPessoais.cpf,
        rg: dadosPessoais.rg,
        telefone: dadosPessoais.telefone,
        genero: dadosPessoais.genero,
        status: dadosPessoais.status,
    });

    const formTextInputs = [
        {
            name: "email",
            tipo: "email",
            label: "E-mail",
            value: formData.email,
            placeholder: "Digite seu e-mail",
            size: "lg",
            required: true,
        },
        {
            name: "cpf",
            label: "CPF",
            value: formData.cpf,
            placeholder: "XXX.XXX.XXX-XX",
            size: "md",
            mask: "999.999.999-99",
            disabled: true,
        },
        {
            name: "rg",
            label: "RG",
            value: formData.rg,
            placeholder: "XX.XXX.XXX-XX",
            size: "md",
            mask: "99.999.999-99",
            disabled: dadosPessoais.rg != null
        },
        {
            name: "dataNascimento",
            label: "Data de Nascimento",
            value: formData.dataNascimento,
            placeholder: "dd/mm/aaaa",
            size: "sm",
            mask: "99/99/9999",
            required: true,
        },
        {
            name: "telefone",
            label: "Telefone",
            value: formData.telefone,
            placeholder: "(XX) XXXXX-XXXX",
            size: "md",
            mask: "(99) 99999-9999",
            required: true,
        },
        {
            name: "genero",
            label: "Gênero",
            value: formData.genero,
            disabled: true
        },
        {
            name: "status",
            label: "Status",
            value: formData.status,
            disabled: true,
        },
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
            ...formData,
            [name]: value === "" ? null : value,
        };
        setFormData(updatedFormData);
    };

    const handleErros = (erros) => {
        const formattedErros = Array.isArray(erros)
            ? erros.map((item) => ({ campo: item.campo, mensagem: item.mensagem }))
            : [{ campo: null, mensagem: erros.mensagem }];
        setErros(formattedErros);
    };

    const handleSubmit = async () => {
        try {
            await editarAfiliado(formData, id);

            navigate(`/afiliados/${id}`);
        } catch (error) {
            handleErros(error.response.data);
        }
    };

    return (
        <>
            <div>
                {formTextInputs.map((input, index) => (
                    <InputLista key={index} input={input} handleInputChange={handleInputChange} />
                ))}
                {erros && erros.map(({ mensagem }) => <p style={{ color: "red" }}>{mensagem}</p>)}
            </div>
            <div className={styles.saveButtonContainer}>
                <Button
                    text={"Salvar Alterações"}
                    backgroundColor={"var(--laranja-principal)"}
                    icon={<IoCheckmarkCircleSharp size={22} />}
                    onClick={handleSubmit}
                />
            </div>
        </>
    );
}

function InputLista({ input, handleInputChange }) {
    const idInput = useId();

    return (
        <div className={styles.itemContainer}>
            <label htmlFor={idInput} className={styles.tituloCampo}>
                {input.label}
            </label>
            <div className={styles.textoCampo}>
                {input.mask ? (
                    <InputMask
                        {...input}
                        className={styles.inputWrapper}
                        id={idInput}
                        onChange={handleInputChange}
                    />
                ) : (
                    <input
                        {...input}
                        className={styles.inputWrapper}
                        id={idInput}
                        onChange={handleInputChange}
                    />
                )}
            </div>
        </div>
    );
}
