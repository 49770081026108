import {CustomModal} from "../CustomModal/CustomModal";
import React, {useContext, useState} from "react";
import {Button} from "../../components/CustomButtons/Button/Button";
import {useDisclosure} from "@chakra-ui/react";
import {IoArrowBackCircle, IoCheckmarkCircleSharp} from "react-icons/io5";
import {AfiliadoContext} from "../../pages/DetalheAfiliado/DetalheAfiliado";
import {FaPlus} from "react-icons/fa6";
import {cadastrarContatoEmergenciaAfiliado} from "../../services/apiService/cadastroAfiliadoService";
import {SuccessModal} from "../SuccessModal/SuccessModal";
import {ContatosEmergenciaForm} from "../../components/AfiliadoForms/ContatosEmergenciaForm/ContatosEmergenciaForm";
import {useMutation} from "@tanstack/react-query";

export function AddContatoEmergencia() {

    const {id} = useContext(AfiliadoContext)

    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
    const { isOpen: isOpenSuccess, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
    const [erro, setErro] =  useState([])

    const [formData, setFormData] = useState();

    const handleErro = (erro) => {
        if (Array.isArray(erro)) {
            const erros = erro.map(item => ({ campo: item.campo, mensagem: item.mensagem }));
            setErro(erros);
        } else {
            setErro([{ campo: null, mensagem: erro.mensagem }]);
        }
    }

    const mutation = useMutation({
        mutationFn: (formData) => cadastrarContatoEmergenciaAfiliado(formData, id),
        onSuccess: () => {
            onCloseModal()
            onOpenSuccess()
        },
        onError: (error) => {
            if (error.response.data) handleErro(error.response.data)
        }
    })

    const handleSubmit = async () => {
        mutation.mutate(formData)
    };



    const modalFooter = (
        <>
            <Button isLoading={mutation.isPending} text={"Voltar"} backgroundColor={"var(--cinza-secundario)"}
                        icon={<IoArrowBackCircle size={22} />}
                        onClick={onCloseModal} />
            <Button isLoading={mutation.isPending} text={"Adicionar"} backgroundColor={"var(--laranja-principal)"}
                        icon={<IoCheckmarkCircleSharp size={22} />}
                        onClick={handleSubmit} />
        </>
    );

    return (
        <>
            <Button icon={<FaPlus/>} onClick={onOpenModal} backgroundColor={"var(--laranja-principal)"}/>

            <CustomModal
                size={'6xl'}
                error={erro}
                onClose={onCloseModal}
                footer={modalFooter}
                isOpen={isOpenModal}
                title={"Novo Contato de Emergência"}
            >
                    <ContatosEmergenciaForm setFormDataChange={(novoFormData) => setFormData(novoFormData)}/>
            </CustomModal>

            <SuccessModal text={"Contato adicionado com sucesso!"} isOpen={isOpenSuccess} onClose={onCloseSuccess}/>
        </>
    )
}
