import styles from "./SecaoDocumentos.module.css"
import CardLayout from "../CardLayout/CardLayout";
import DocumentCardMinus from "../DocumentCardMinus/DocumentCardMinus";
import {Button} from "../CustomButtons/Button/Button";
import CadastroDocumentoModal from "../../modals/CadastroDocumentoModal/CadastroDocumentoModal";
import {useContext} from "react";
import {AfiliadoContext} from "../../pages/DetalheAfiliado/DetalheAfiliado";
import {FaPlus} from "react-icons/fa6";

export function SecaoDocumento({ documentos }) {
    const { id } = useContext(AfiliadoContext)

    const button = (
        <Button
            icon={<FaPlus size={25}/>}
            backgroundColor="var(--laranja-principal)"
        />
    );

    return (
        <CardLayout title={"Documentos do Afiliado"} fullSize>
            <div className={styles.buttonContainer}>
                <CadastroDocumentoModal button={button} id={id}/>
            </div>
            {documentos && documentos.map((documento, index) => (
                <DocumentCardMinus
                    key={index}
                    idDocumento={documento.id}
                    nomeArquivo={documento.nome}
                    formatoArquivo={documento.formato}
                    tamanhoArquivo={documento.tamanho}
                    linkDownload={documento.downloadLink}
                    corBackground={index % 2 === 0 ? "#EBEBEB" : "#FFF"}
                />
            ))}
        </CardLayout>
    )
} 