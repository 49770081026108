import {useContext, useState} from "react";
import ToggleBar from "../../ToggleBar/ToggleBar";
import {getDadosEnderecoAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import styles from './EnderecoToggleBar.module.css'
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";
import {EditEndereco} from "../../../modals/EditAfiliadoModals/EditEndereco/EditEndereco";

export function EnderecoToggleBar() {
    const [endereco, setEndereco] =  useState()
    const {edit} = useContext(AfiliadoContext)

    return (
        <ToggleBar modal={edit && <EditEndereco/>} edit={edit} label={"Endereço"} apiService={getDadosEnderecoAfiliado} setData={setEndereco}>
            {endereco ?
                (
                <>
                    <Campo campo={"CEP"} texto={endereco.cep}/>
                    <Campo campo={"Logradouro"} texto={endereco.logradouro}/>
                    <Campo campo={"Bairro"} texto={endereco.bairro}/>
                    <Campo campo={"Cidade"} texto={endereco.cidade}/>
                    <Campo campo={"Estado"} texto={endereco.estado}/>
                    <Campo campo={"Número"} texto={endereco.numero}/>
                    <Campo campo={"Complemento"} texto={endereco.complemento}/>
                </>
                ) : <p>Não está cadastrado um endereço</p>
            }

        </ToggleBar>
    )
}

function Campo({campo, texto}) {
    return (
        <div className={styles.campo}>
            <h5>{campo}</h5>
            <p>{texto ? texto : "-"}</p>
        </div>
    )
}