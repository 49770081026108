import CardLayout from "../CardLayout/CardLayout";
import userDefault from '../../assets/images/default-user-image.png'
import {FiEdit3, FiTrash} from "react-icons/fi";
import styles from './DadosPessoaisAfiliado.module.css'
import {TbDatabase} from "react-icons/tb";
import {Link} from "react-router-dom";
import {cloneElement, useContext} from "react";
import {AfiliadoContext} from "../../pages/DetalheAfiliado/DetalheAfiliado";
import {deletarAfiliado} from "../../services/apiService/editarAfiliadoService";
import {CustomApagarModal} from "../../modals/CustomApagarModal/CustomApagarModal";
import {TiArrowBackOutline} from "react-icons/ti";


export function SecaoDadosPessoaisAfiliado({ dadosPessoais, children } ) {
    const {id, edit} = useContext(AfiliadoContext);

    return (
        <CardLayout fullSize>
            <div className={styles.imageContainer}>
                <img alt={"Imagem de perfil do afiliado"}
                     src={dadosPessoais.imagemPerfil ? `data:image/jpeg;base64,${dadosPessoais.imagemPerfil} `: userDefault}
                     className={styles.image}/>
            </div>

            <div className={styles.buttonsContainer}>
                {edit ?
                    (
                        <Link to={`/afiliados/${id}`}><TiArrowBackOutline size={28} color={"var(--laranja-principal)"} /></Link>
                    ) : (
                    <>
                        <CustomApagarModal
                            text={"Deseja apagar esse afiliado?"}
                            apiService={() => deletarAfiliado(id)}
                            button={ <a><FiTrash size={25} color={"var(--laranja-principal)"}/></a> }
                            sendTo={"/afiliados"}
                        />
                        <Link to={`/afiliados/${id}/editar`}> <FiEdit3 size={25} color={"var(--laranja-principal)"}/> </Link>
                    </>
                )}
            </div>

            <div className={styles.cardContainer}>
                <div className={styles.tituloContainer}>
                    <h2>{dadosPessoais.nome}</h2>
                    <h5>Membro desde {dadosPessoais.dataCriacao}</h5>
                </div>

                <div className={styles.dadosContainer}>
                    <div className={styles.tituloSecao}>
                        <TbDatabase /> <p>Dados do Afiliado</p>
                    </div>
                    {cloneElement(children, {dadosPessoais: dadosPessoais})}
                </div>
            </div>
        </CardLayout>
    )
}