import React from 'react';
import {TextoAuxiliar} from "../../components/TextoAuxiliar/TextoAuxiliar";
import {AuthCardLogin} from "../../components/AuthCardLogin/AuthCardLogin";

export default function LoginAdmin() {
    return (
        <>
            <AuthCardLogin/>
            <TextoAuxiliar
                upperTitulo={"Página de"}
                titulo={"Login"}
                textoAuxiliar={"A esperança traz força através de atitudes"}
            />
        </>
    )
}