import {axiosInstance} from "../axiosInstance";

export const editarAfiliado = async (formData, id) => {
    const response = await axiosInstance.put(`/afiliados/${id}`, formData)
    return response.data
}

export const editarContatosEmergencia = async (formData, id) => {
    const response = await axiosInstance.put(`/afiliados/${id}/contatos-emergencia`, formData)
    return response.data
}

export const editarHistoricoInformacoes = async (formData, id) => {
    const response = await axiosInstance.put(`/afiliados/${id}/historico-informacoes`, formData)
    return response.data
}

export const deletarHistoricoInformacoes = async (id, informacaoId) => {
    const response = await axiosInstance.delete(`/afiliados/${id}/historico-informacoes/${informacaoId}`)
    return response.data
}

export const deletarContatoEmergencia = async (id, contatoId) => {
    const response = await axiosInstance.delete(`/afiliados/${id}/contatos-emergencia/${contatoId}`)
    return response.data
}

export const deletarAfiliado = async (id) => {
    const response = await axiosInstance.delete(`/afiliados/${id}`)
    return response.data
}

export const deletarDocumentoAfiliado = async (id, idDocumento) => {
    const response = await axiosInstance.delete(`/afiliados/${id}/documentos/${idDocumento}`)
    return response.data
}