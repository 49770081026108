import styles from './PaginaPrincipal.module.css';
import LinksRapidos from "../../components/LinksRapidos/LinksRapidos";
import ImagemCard from "../../components/ImagemCard/ImagemCard";


export default function PaginaPrincipal() {

  return (
      <div className={styles.paginaPrincipalContainer}>
          <LinksRapidos/>
          <ImagemCard/>
      </div>
  )
}