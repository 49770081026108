import {useEffect, useState} from 'react';
import {CustomInput} from "../../CustomInput/CustomInput";
import {CustomOptionInput} from "../../CustomInput/CustomOptionInput";
import {getParentesco} from "../../../services/apiService/opcoesServices";
import {getContatosEmergenciaAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import styles from '../AfiliadoForms.module.css'
import {CustomApagarModal} from "../../../modals/CustomApagarModal/CustomApagarModal";
import {deletarContatoEmergencia} from "../../../services/apiService/editarAfiliadoService";
import {FiTrash} from "react-icons/fi";

export function ContatosEmergenciaForm({ edit, setFormDataChange, id }) {
    const [editFormData, setEditFormData] = useState([]);
    const [formData, setFormData] = useState({
        nome: null,
        telefonePrincipal: null,
        telefoneAlternativo: null,
        parentesco: null,
    });

    useEffect(() => {
        const fetchAfiliadoData = async (id) => {
            try {
                const response = await getContatosEmergenciaAfiliado(id);
                setEditFormData(response);
            } catch (error) {
                console.log(error);
            }
        };

        if (edit) {
            fetchAfiliadoData(id);
        }
    }, [edit, id]);

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedEditFormData = [...editFormData];

        if (edit) {
            updatedEditFormData[index][name] = value;
            setEditFormData(updatedEditFormData);
        } else {
            setFormData({ ...formData, [name]: value });
        }

        setFormDataChange(edit ? updatedEditFormData : { ...formData, [name]: value });
    };

    const renderContactInputs = (contact, index) => (
        <div key={index} className={styles.contatoWrapper}>
            <CustomInput
                onChange={(event) => handleInputChange(event, index)}
                name="nome"
                label="Nome"
                value={contact.nome || null}
                placeholder="Digite o nome do contato"
                size="lg"
                required
            />
            <CustomInput
                onChange={(event) => handleInputChange(event, index)}
                name="telefonePrincipal"
                label="Telefone"
                value={contact.telefonePrincipal || null}
                placeholder="(XX) XXXXX-XXXX"
                mask="(99) 99999-9999"
                size="md"
                required
            />
            <CustomInput
                onChange={(event) => handleInputChange(event, index)}
                name="telefoneAlternativo"
                label="Telefone Alternativo"
                value={contact.telefoneAlternativo || null}
                placeholder="(XX) XXXX-XXXX"
                mask="(99) 9999-9999?9"
                size="md"
                required={false}
            />
            <CustomOptionInput
                onChange={(event) => handleInputChange(event, index)}
                name="parentesco"
                label="Parentesco"
                value={contact.parentesco || null}
                placeholder="Selecione o parentesco"
                optionService={getParentesco}
                required
            />
            {edit && editFormData && (
                <CustomApagarModal
                    text={"Deseja apagar esse contato?"}
                    apiService={() => deletarContatoEmergencia(id, contact.id)}
                    button={<FiTrash className={styles.deleteButton} size={40} color={"red"}/>}
                    sendTo={`/afiliados/${id}`}
                />
            )}
        </div>
    );

    return (
        <>
            {(!edit || !editFormData) && renderContactInputs(formData, 0)}
            {edit && editFormData && editFormData.map(renderContactInputs)}
        </>
    );
}