import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {cadastrarEnderecoAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {CadastroAfiliadoForm} from "../../../components/CadastroAfiliadoForm/CadastroAfiliadoForm";
import {DadosdeEnderecoForm} from "../../../components/AfiliadoForms/DadosdeEnderecoForm/DadosdeEnderecoForm";
import {useMutation} from "@tanstack/react-query";


export function CadastroDadosdeEndereco() {

    const { id } = useParams();

    const [formData, setFormData] = useState();
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: ({formData}) => cadastrarEnderecoAfiliado(formData, id),
        onSuccess: (data, { isFinalizar}) => {
            if (isFinalizar === true) {
                navigate(`/afiliados/${id}`);
            } else {
                navigate(`/afiliados/${id}/dados-medicos`);
            }
        },
        onError: (error, { handleErro }) => {
            if (error.response.data) handleErro(error.response.data);
        }
    });

    const handleSubmit = (event, isFinalizar, handleErro) => {
        event.preventDefault();
        mutation.mutate({ formData, isFinalizar, handleErro });
    };


    return (
        <CadastroAfiliadoForm isLoading={mutation.isPending} title={"Cadastro - Dados do Endereço"} handleSubmit={handleSubmit} step={2} proximoButton>
            <DadosdeEnderecoForm setFormDataChange={(novoFormData) => setFormData(novoFormData)}/>
        </CadastroAfiliadoForm>
    )
}
