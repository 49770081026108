import {useEffect, useState} from "react";
import {CustomInput} from "../../CustomInput/CustomInput";
import {CustomOptionInput} from "../../CustomInput/CustomOptionInput";
import {getEtnias, getNiveisEducacao, getOrientacoesSexuais} from "../../../services/apiService/opcoesServices";
import {getDadosDemograficosAfiliado} from "../../../services/apiService/dadosAfiliadoService";

export function DadosDemograficosForm({edit, setFormDataChange, id}) {


    const [formData, setFormData] = useState({
        rendaAnual: null,
        etnia: null,
        nivelEducacao: null,
        orientacaoSexual: null,
    });

    useEffect(() => {
        const fetchDadosDemograficos = async (id) => {
            try {
                const response = await getDadosDemograficosAfiliado(id)

                setFormData(response)
            }
            catch (error) {
                console.log(error)
            }
        }

        if (edit) {
            fetchDadosDemograficos(id)
        }


    }, [edit, id]);

    const formTextInputs = [
        {
            name: "rendaAnual",
            label: "Renda Anual",
            value: formData.rendaAnual,
            placeholder: "XXXX,XX",
            size: "sm",
            required: true,
        },
    ];
    const formOptionInputs = [
        {
            name: "etnia",
            label: "Etnia",
            value: formData.etnia,
            placeholder: "Selecione a etnia",
            optionService: getEtnias,
            required: true,
        },
        {
            name: "nivelEducacao",
            label: "Nível de Educação",
            value: formData.nivelEducacao,
            placeholder: "Selecione o nível de educação",
            optionService: getNiveisEducacao,
            required: true,
        },
        {
            name: "orientacaoSexual",
            label: "Orientação Sexual",
            value: formData.orientacaoSexual,
            placeholder: "Selecione a orientação sexual",
            optionService: getOrientacoesSexuais,
            required: true,
        },
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
            ...formData,
            [name]: value === "" ? null : value,
        };
        setFormData(updatedFormData);
        setFormDataChange(updatedFormData);
    };

    return (
        <>
            {formOptionInputs.map((input, index) => <CustomOptionInput
                key={index}
                onChange={handleInputChange}
                {...input}
            />)}
            {formTextInputs.map((input, index) =>
                <CustomInput
                    key={index}
                    onChange={handleInputChange}
                    {...input}
                />)}
        </>
    )
}