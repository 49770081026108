import {useQuery} from "@tanstack/react-query";
import {BarraDePesquisa} from "../../components/BarraDePesquisa/BarraDePesquisa";
import {useState} from "react";
import styles from './BuscaAfiliados.module.css'
import {Filtro} from "../../components/Filtro/Filtro";
import {Button} from "../../components/CustomButtons/Button/Button";
import {IoAddCircleOutline} from "react-icons/io5";
import Card from "../../components/CardAfiliados/CardAfiliados";
import {buscarAfiliados} from "../../services/apiService/dadosAfiliadoService";
import {getCidades, getStatus, getTiposSanguineos} from "../../services/apiService/opcoesServices";


export default function BuscaAfiliados() {
    const [searchData, setSearchData] = useState({ q: '', cidades: [], tiposSanguineos: [], status: [] });
    const [q, setQ] = useState("");
    const [cidades, setCidades] = useState([]);
    const [tiposSanguineos, setTiposSanguineos] = useState([]);
    const [status, setStatus] = useState([]);

    const { data: afiliados } = useQuery({
        queryKey: ['afiliados', searchData],
        queryFn: () => buscarAfiliados(searchData)
    });

    const handleSearch = () => {
        setSearchData({ q, cidades, tiposSanguineos, status });
    }

    const BotaoAddAfiliado = <Button text={"Adicionar Afiliado"} link={"/afiliados/cadastro"} backgroundColor={"#FF4A42"} fontSize={"20px"} icon={<IoAddCircleOutline size={30}/>}/>

    return (
        <div className={styles.buscaAfiliadosContainer}>
            <BarraDePesquisa
                titulo={"Buscar Afiliados"}
                onInputChange={setQ}
                handleSubmit={handleSearch}
                placeholder={"Busque pelo nome ou documento do afiliado"}
                adicionar={BotaoAddAfiliado}
            >
                <Filtro placeholder={"Cidades"} opcaoApiService={getCidades} onChange={setCidades}/>
                <Filtro placeholder={"Tipo Sanguíneo"} opcaoApiService={getTiposSanguineos} onChange={setTiposSanguineos}/>
                <Filtro placeholder={"Status"} opcaoApiService={getStatus} onChange={setStatus}/>
            </BarraDePesquisa>
            <div className={styles.afiliadosContainer}>
                {afiliados && afiliados.map(afiliado => (
                    <Card
                        id={afiliado.id}
                        idade={afiliado.idade}
                        imagemPerfil={afiliado.imagemPerfil}
                        nome={afiliado.nome}
                        tipoSanguineo={afiliado.tipoSanguineo}/>
                ))}
            </div>
        </div>
    );
}
