import {useContext, useState} from "react";
import ToggleBar from "../../ToggleBar/ToggleBar";
import {getHistoricoInformacoesAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import styles from './InformacoesToggleBar.module.css'
import {AddHistoricoInformacoes} from "../../../modals/AddHistoricoInformacoes/AddHistoricoInformacoes";
import moment from "moment";
import {
    EditHistoricoInformacoes
} from "../../../modals/EditAfiliadoModals/EditHistoricoInformacoes/EditHistoricoInformacoes";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";

export function InformacoesToggleBar() {
    const [historicoInformacoes, setHistoricoInformacoes] = useState([])
    const {edit} = useContext(AfiliadoContext)

    return (
        <ToggleBar
            label={"Histórico de Informações"}
            setData={setHistoricoInformacoes}
            apiService={getHistoricoInformacoesAfiliado}
            modal={edit ? <EditHistoricoInformacoes/> : <AddHistoricoInformacoes/>}
            edit={edit}
        >
            {historicoInformacoes ?
                (
                    <div className={styles.container}>
                        <table className={styles.camposTable}>
                            <thead>
                            <tr>
                                <th className={styles.observacao}>Observação</th>
                                <th className={styles.dia}>Dia</th>
                                <th className={styles.horario}>Horário</th>
                            </tr>
                            </thead>
                            <tbody>
                            {historicoInformacoes.map((informacao, index) =>
                                <tr key={index}>
                                    <td className={styles.observacao}>{informacao.observacao}</td>
                                    <td className={styles.dia}>{moment(informacao.diaHorario).format("DD/MM/yyyy")}</td>
                                    <td className={styles.horario}>{moment(informacao.diaHorario).format("HH:mm")}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                ) : <p>Não está cadastrada nenhuma informação anterior</p>
            }
        </ToggleBar>
    )
}
