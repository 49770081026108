// Aqui será implementado todas as requisições para a api utilizando a instância do axios
import {axiosInstance} from '../axiosInstance'

export const fazerLogin = async (authData) => {
    const response = await axiosInstance.post("/login", authData)
    return response
}

export const cadastrarNovoAdmin = async (adminData) => {
    const response = await axiosInstance.post("/cadastro", adminData)
    return response.data
}