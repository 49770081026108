import Navbar from "../Navbar/Navbar";
import {Outlet} from "react-router-dom";
import styles from './Containers.module.css'

// Container das páginas com Navbar
export default function Container( {customClass} ) {
    return (
        <div className={styles.container}>
            <Navbar/>
            <main className={`${styles.content} ${customClass}`}>
                <Outlet />
            </main>
        </div>
    )
}
