import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {cadastrarContatoEmergenciaAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {ContatosEmergenciaForm} from "../../../components/AfiliadoForms/ContatosEmergenciaForm/ContatosEmergenciaForm";
import {CadastroAfiliadoForm} from "../../../components/CadastroAfiliadoForm/CadastroAfiliadoForm";
import {useMutation} from "@tanstack/react-query";

export function CadastroContatoEmergencia() {
    const { id } = useParams();
    const [formData, setFormData] = useState();
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: ({formData}) => cadastrarContatoEmergenciaAfiliado(formData, id),
        onSuccess: (data, { isFinalizar}) => {
            if (isFinalizar) {
                navigate(`/afiliados/${id}`);
            } else {
                navigate(`/afiliados/${id}/dados-demograficos`);
            }
        },
        onError: (error, { handleErro }) => {
            if (error.response.data) handleErro(error.response.data);
        }
    });

    const handleSubmit = (event, isFinalizar, handleErro) => {
        event.preventDefault();
        mutation.mutate({ formData, isFinalizar, handleErro });
    };

    return (
        <CadastroAfiliadoForm isLoading={mutation.isPending} handleSubmit={handleSubmit} title={"Cadastro - Contatos de Emergência"} proximoButton step={4}>
            <ContatosEmergenciaForm setFormDataChange={(novoFormData) => setFormData(novoFormData)}/>
        </CadastroAfiliadoForm>
    )
}
