import {EditAfiliadoModal} from "../EditAfiliadoModal";
import {cadastrarEnderecoAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import React, {useContext, useState} from "react";
import {DadosdeEnderecoForm} from "../../../components/AfiliadoForms/DadosdeEnderecoForm/DadosdeEnderecoForm";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";

export function EditEndereco() {
    const { id } = useContext(AfiliadoContext)
    const [formData, setFormData] = useState()


    return (
        <EditAfiliadoModal size={"4xl"} submitApiService={cadastrarEnderecoAfiliado} formData={formData} title={"Editar Endereço"}>
            <DadosdeEnderecoForm setFormDataChange={(novoFormData) => setFormData(novoFormData)} edit id={id}/>
        </EditAfiliadoModal>
    );
}
