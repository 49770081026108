import {useState} from "react";
import styles from '../AfiliadoForms.module.css'
import {CustomInput} from "../../CustomInput/CustomInput";
import {CustomOptionInput} from "../../CustomInput/CustomOptionInput";
import {ImageForm} from "../../imageForm/ImageForm";
import {getGeneros} from "../../../services/apiService/opcoesServices";


export function DadosPessoaisForm({setImagemPerfilChange, setFormDataChange}) {

    const [formData, setFormData] = useState({
        nome: null,
        email: null,
        dataNascimento: null,
        cpf: null,
        rg: null,
        telefone: null,
        genero: null,
    });

    const formTextInputs = [
        {
            name: "nome",
            label: "Nome",
            value: formData.nome,
            placeholder: "Digite seu nome",
            size: "lg",
            required:true,
        },
        {
            name: "email",
            tipo: "email",
            label: "E-mail",
            value: formData.email,
            placeholder: "Digite seu e-mail",
            size: "lg",
            required:true,
        },
        {
            name: "dataNascimento",
            label: "Data de nascimento",
            value: formData.dataNascimento,
            placeholder: "dd/mm/aaaa",
            size: "sm",
            mask: "99/99/9999",
            required:true,
        },
        {
            name: "cpf",
            label: "CPF",
            value: formData.cpf,
            placeholder: "XXX.XXX.XXX-XX",
            size: "md",
            mask: "999.999.999-99",
            required:true,
        },
        {
            name: "rg",
            label: "RG",
            value: formData.rg,
            placeholder: "XX.XXX.XXX-XX",
            size: "md",
            mask: "99.999.999-99",
        },
        {
            name: "telefone",
            label: "Telefone",
            value: formData.telefone,
            placeholder: "(XX) XXXXX-XXXX",
            size: "md",
            mask: "(99) 99999-9999",
            required:true,
        },
    ];
    const formOptionInputs = [
        {
            name: "genero",
            label: "Gênero",
            value: formData.genero,
            placeholder: "Selecione o seu gênero",
            optionService: getGeneros,
            required:true,
        },
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
            ...formData,
            [name]: value === "" ? null : value,
        };
        setFormData(updatedFormData);
        setFormDataChange(updatedFormData)
    };

    return (
        <>
            <ImageForm setImage={(imagemPerfil) => setImagemPerfilChange(imagemPerfil)}/>
            <div className={styles.textSection}>
                {formTextInputs.map((input, index) =>
                    <CustomInput
                        key={index}
                        onChange={handleInputChange}
                        {...input}
                    />)}
                {formOptionInputs.map((input, index) => <CustomOptionInput
                    key={index}
                    onChange={handleInputChange}
                    {...input}
                />)}
            </div>
        </>
    )
}
