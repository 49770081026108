import {getContatosEmergenciaAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import ToggleBar from "../../ToggleBar/ToggleBar";
import styles from './ContatosToggleBar.module.css'
import {useContext, useState} from "react";
import {AddContatoEmergencia} from "../../../modals/AddContatoEmergencia/AddContatoEmergencia";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";
import {EditContatosEmergencia} from "../../../modals/EditAfiliadoModals/EditContatosEmergencia/EditContatosEmergencia";

export function ContatosToggleBar() {
    const [contatosEmergencia, setContatosEmergencia] = useState([])
    const {edit} = useContext(AfiliadoContext)

    return (
        <ToggleBar
            label={"Contatos de Emergência"}
            setData={setContatosEmergencia}
            apiService={getContatosEmergenciaAfiliado}
            modal={edit ? <EditContatosEmergencia/> : <AddContatoEmergencia/>}
            edit={edit}
        >
            {contatosEmergencia ?
                (
                    <div className={styles.container}>
                        <table className={styles.camposTable}>
                            <thead>
                                <tr>
                                    <th className={styles.posicao}>Posição</th>
                                    <th className={styles.nome}>Nome do Contato</th>
                                    <th className={styles.telefone}>Telefone</th>
                                    <th className={styles.telefoneAlt}>Telefone Alt.</th>
                                    <th className={styles.parentesco}>Parentesco</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contatosEmergencia.map((contato, index) =>
                                    <tr key={index}>
                                        <td className={styles.posicao}>{index + 1}</td>
                                        <td className={styles.nome}>{contato.nome}</td>
                                        <td className={styles.telefone}>{contato.telefonePrincipal}</td>
                                        <td className={styles.telefoneAlt}>{contato.telefoneAlternativo ? contato.telefoneAlternativo : "-"}</td>
                                        <td className={styles.parentesco}>{contato.parentesco}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : <p>Não está cadastrado nenhum contato de emergência</p>
            }
        </ToggleBar>
    )
}