import {getDadosDemograficosAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import ToggleBar from "../../ToggleBar/ToggleBar";
import {useContext, useState} from "react";
import styles from './DadosDemograficosToggleBar.module.css'
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";
import {EditDadosDemograficos} from "../../../modals/EditAfiliadoModals/EditDadosDemograficos/EditDadosDemograficos";

export function DadosDemograficosToggleBar() {
    const [dadosDemograficos, setDadosDemograficos] = useState()
    const {edit} = useContext(AfiliadoContext)


    return (
        <ToggleBar modal={edit && <EditDadosDemograficos/>} edit={edit} label={"Dados Demográficos"} setData={setDadosDemograficos} apiService={getDadosDemograficosAfiliado}>
            {dadosDemograficos ?
                (
                <>
                    <Campo campo={"Etnia"} texto={dadosDemograficos.etnia}/>
                    <Campo campo={"Nível de Educação"} texto={dadosDemograficos.nivelEducacao}/>
                    <Campo campo={"Orientação Sexual"} texto={dadosDemograficos.orientacaoSexual}/>
                    <Campo campo={"Renda Anual"} texto={`R$ ${dadosDemograficos.rendaAnual}`}/>
                </>
                ) : <p>Não está cadastrado nenhum dado demográfico</p>
            }
        </ToggleBar>
    )
}

function Campo({campo, texto}) {
    return (
        <div className={styles.campo}>
            <h5>{campo}</h5>
            <p>{texto ? texto : "-"}</p>
        </div>
    )
}