import React, {useContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import AuthContainer from "./components/Containers/AuthContainer";
import Container from "./components/Containers/Container";
import LoginAdmin from './pages/LoginAdmin/LoginAdmin';
import CadastroAdmin from './pages/CadastroAdmin/CadastroAdmin';
import PaginaPrincipal from "./pages/PaginaPrincipal/PaginaPrincipal";
import BuscaAfiliados from "./pages/BuscaAfiliados/BuscaAfiliados";
import BuscaDocumentos from "./pages/BuscaDocumentos/BuscaDocumentos";
import {DetalheAfiliado} from "./pages/DetalheAfiliado/DetalheAfiliado";
import {EditarAfiliado} from "./pages/EditarAfiliado/EditarAfiliado";
import {CadastroDadosPessoais} from "./pages/CadastroAfiliados/DadosPessoais/CadastroDadosPessoais";
import {CadastroDadosMedicos} from "./pages/CadastroAfiliados/DadosMedicos/CadastroDadosMedicos";
import {CadastroDadosDemograficos} from './pages/CadastroAfiliados/DadosDemograficos/CadastroDadosDemograficos';
import {CadastroContatoEmergencia} from "./pages/CadastroAfiliados/ContatosEmergencia/CadastroContatoEmergencia";
import {CadastroDadosdeEndereco} from './pages/CadastroAfiliados/DadosdeEndereco/CadastroDadosdeEndereco';
import {AuthContext} from "./context/AuthContext";
import {Loading} from "./components/Loading/Loading";

function PrivateRoute({ children }) {
    const { loading, authenticated } = useContext(AuthContext);

    if (loading) {
        return <Loading/>;
    }

    if (!authenticated) {
        return <Navigate to="/auth/login" />
    }

    return children;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "auth",
                element: <AuthContainer/>,
                children: [
                    {
                        path:"login",
                        element: <LoginAdmin/>,
                    },
                    {
                        path:"cadastro",
                        element: (
                            <PrivateRoute>
                                <CadastroAdmin/>
                            </PrivateRoute>
                        ),
                    },
                ]
            },
            {
                path: "",
                element: (
                    <PrivateRoute>
                        <Container/>
                    </PrivateRoute>
                ),
                children: [
                    {
                        path:"",
                        element: <PaginaPrincipal/>,
                    },
                    {
                        path:"afiliados",
                        element: <BuscaAfiliados/>,
                    },
                    {
                        path:"documentos",
                        element: <BuscaDocumentos/>,
                    },
                    {
                        path:"afiliados/:id",
                        element: <DetalheAfiliado/>,
                    },
                    {
                        path:"afiliados/:id/editar",
                        element: <EditarAfiliado/>,
                    },
                    {
                        path:"afiliados/cadastro",
                        element: <CadastroDadosPessoais/>,
                    },
                    {
                        path:"afiliados/:id/dados-medicos",
                        element: <CadastroDadosMedicos/>
                    },
                    {
                        path:"afiliados/:id/dados-demograficos",
                        element: <CadastroDadosDemograficos/>
                    },
                    {
                        path:"afiliados/:id/contatos-emergencia",
                        element: <CadastroContatoEmergencia/>,
                    },
                    {
                        path:"afiliados/:id/endereco",
                        element: <CadastroDadosdeEndereco/>
                    }

                ]
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)