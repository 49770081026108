import {IoIosArrowDropdown} from "react-icons/io";
import {FaUser} from "react-icons/fa";
import {Dropdown} from "antd";
import * as React from "react";
import {useContext} from "react";
import {CustomItemDropdown} from "../ItemDropdown/CustomItemDropdown";
import {MdOutlineAdminPanelSettings} from "react-icons/md";
import {IoLogOutOutline} from "react-icons/io5";
import {AuthContext} from "../../../context/AuthContext";
import {Link} from "react-router-dom";


export default function AdminDropdown( {className} ) {
    const {handleLogout} = useContext(AuthContext)

    const items = [
        {
            key: '1',
            label: ( <CustomItemDropdown text={"Novo Administrador"} to={"/auth/cadastro"} icon={<MdOutlineAdminPanelSettings/>} /> ),
        },
        {
            key: '2',
            label: ( <CustomItemDropdown text={"Logout"} onClick={handleLogout} icon={<IoLogOutOutline/>} /> ),
        },
    ];

    return (
        <Dropdown menu={{items,}} placement="bottomRight" className={className}>
            <Link>
                <FaUser/>
                <IoIosArrowDropdown/>
            </Link>
        </Dropdown>
    )
}