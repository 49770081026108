import {axiosInstance} from "../axiosInstance";

export const buscarAfiliados = async (dadosPesquisa) => {
    const response = await axiosInstance.get("/afiliados/buscar", {
        params: dadosPesquisa,
        paramsSerializer: {indexes: null}
    });
    return response.data;
}

export const getDadosPessoaisAfiliado = async (id) => {
    const response = await axiosInstance.get(`/afiliados/${id}`)
    return response.data
}

export const getDadosEnderecoAfiliado = async (id) => {
    const response = await axiosInstance.get(`/afiliados/${id}/endereco`)
    return response.data
}

export const getDadosMedicosAfiliado = async (id) => {
    const response = await axiosInstance.get(`/afiliados/${id}/dados-medicos`)
    return response.data
}

export const getDadosDemograficosAfiliado = async (id) => {
    const response = await axiosInstance.get(`/afiliados/${id}/dados-demograficos`)
    return response.data
}

export const getContatosEmergenciaAfiliado = async (id) => {
    const response = await axiosInstance.get(`/afiliados/${id}/contatos-emergencia`)
    return response.data
}

export const getHistoricoInformacoesAfiliado = async (id) => {
    const response = await axiosInstance.get(`/afiliados/${id}/historico-informacoes`)
    return response.data
}