import styles from "./CustomItemDropdown.module.css";
import {Link} from "react-router-dom";
import * as React from "react";

export function CustomItemDropdown({to, text, icon, onClick}) {
    return (
        <div onClick={onClick} className={styles.itemDropdown}>
            {icon}
            {to ? <Link to={to} >{text}</Link> : <a>{text}</a> }
        </div>
    )
}