import {axiosInstance} from "../axiosInstance";

export const getCidades = async () => {
    const response = await axiosInstance.get("/opcoes/cidade")
    return response.data
}

export const getFormatos = async () => {
    const response = await axiosInstance.get("/opcoes/tipo-documento")
    return response.data
}

export const getTiposSanguineos = async () => {
    const response = await axiosInstance.get("/opcoes/tipo-sanguineo")
    return response.data
}

export const getStatus = async () => {
    const response = await axiosInstance.get("/opcoes/status")
    return response.data
}

export const getGeneros = async () => {
    const response = await axiosInstance.get("/opcoes/genero")
    return response.data
}

export const getTiposHemoglobinas = async () => {
    const response = await axiosInstance.get("/opcoes/tipo-hemoglobina")
    return response.data
}

export const getEtnias = async () => {
    const response = await axiosInstance.get("/opcoes/etnia")
    return response.data
}

export const getNiveisEducacao = async () => {
    const response = await axiosInstance.get("/opcoes/nivel-educacao")
    return response.data
}

export const getOrientacoesSexuais = async () => {
    const response = await axiosInstance.get("/opcoes/orientacao-sexual")
    return response.data
}

export const getParentesco = async () => {
    const response = await axiosInstance.get("/opcoes/parentesco")
    return response.data
}
