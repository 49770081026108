import {useParams} from "react-router-dom";
import styles from "./EditarAfiliado.module.css";
import {DadosAuxiliaresAfiliado} from "../../components/DadosAuxiliaresAfiliado/DadosAuxiliaresAfiliado";
import {AfiliadoContext} from "../DetalheAfiliado/DetalheAfiliado";
import {SecaoDocumento} from "../../components/SecaoDocumentos/SecaoDocumentos";
import {SecaoDadosPessoaisAfiliado} from "../../components/DadosPessoaisAfiliado/SecaoDadosPessoaisAfiliado";
import {useQuery} from "@tanstack/react-query";
import {getDadosPessoaisAfiliado} from "../../services/apiService/dadosAfiliadoService";
import {Loading} from "../../components/Loading/Loading";
import {EditarDadosPessoais} from "../../components/DadosPessoaisAfiliado/EditarDadosPessoais/EditarDadosPessoais";

export function EditarAfiliado() {
    const {id} = useParams()
    const edit = true

    const {data: dadosPessoais, isLoading} = useQuery({
        queryFn: () => getDadosPessoaisAfiliado(id),
        queryKey: [id],
    })

    return (
        <AfiliadoContext.Provider value={{id, edit}}>

            {
                isLoading ? <Loading/> :
                    <div className={styles.detalheContainer}>
                        <div className={styles.colunaLateral}>
                            <SecaoDadosPessoaisAfiliado dadosPessoais={dadosPessoais}>
                                <EditarDadosPessoais/>
                            </SecaoDadosPessoaisAfiliado>
                            <SecaoDocumento documentos={dadosPessoais.documentos}/>
                        </div>

                        <div className={styles.colunaDadosAuxiliares}>
                            <DadosAuxiliaresAfiliado/>
                        </div>
                    </div>
            }
        </AfiliadoContext.Provider>
    )
}