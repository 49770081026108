import {useEffect, useState} from 'react';
import {CustomInput} from "../../CustomInput/CustomInput";
import {getHistoricoInformacoesAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import styles from '../AfiliadoForms.module.css'
import {CustomApagarModal} from "../../../modals/CustomApagarModal/CustomApagarModal";
import {deletarHistoricoInformacoes} from "../../../services/apiService/editarAfiliadoService";
import {FiTrash} from "react-icons/fi";

export function HistoricoInformacoesForm({ edit, setFormDataChange, id }) {
    const [editFormData, setEditFormData] = useState([]);
    const [formData, setFormData] = useState({
        observacao: "",
        diaHorario: "",
    })

    useEffect(() => {
        const fetchAfiliadoData = async (id) => {
            try {
                const response = await getHistoricoInformacoesAfiliado(id);
                setEditFormData(response);
            } catch (error) {
                console.log(error);
            }
        };

        if (edit) {
            fetchAfiliadoData(id);
        }
    }, [edit, id]);

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedEditFormData = [...editFormData];

        if (edit) {
            updatedEditFormData[index][name] = value;
            setEditFormData(updatedEditFormData);
        } else {
            setFormData({ ...formData, [name]: value });
        }

        setFormDataChange(edit ? updatedEditFormData : { ...formData, [name]: value });
    };

    const renderContactInputs = (informacao, index) => (
        <div key={index} className={styles.contatoWrapper}>
            <CustomInput
                onChange={(event) => handleInputChange(event, index)}
                name="observacao"
                label={`Observação`}
                value={informacao.observacao || null}
                size="lg"
                required
            />
            <CustomInput
                onChange={(event) => handleInputChange(event, index)}
                name="diaHorario"
                label="Dia e Horário"
                value={informacao.diaHorario || null}
                size="sm"
                required
                tipo="datetime-local"
            />
            {edit && editFormData && (
                <CustomApagarModal
                    text={"Deseja apagar esse contato?"}
                    apiService={() => deletarHistoricoInformacoes(id, informacao.id)}
                    button={<FiTrash className={styles.deleteButton} size={40} color={"red"}/>}
                    sendTo={`/afiliados/${id}`}
                />
            )}
        </div>
    );

    return (
        <>
            {(!edit || !editFormData) && renderContactInputs(formData, 0)}
            {edit && editFormData && editFormData.map(renderContactInputs)}
        </>
    );
}