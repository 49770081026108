import {getDadosMedicosAfiliado} from "../../../services/apiService/dadosAfiliadoService";
import ToggleBar from "../../ToggleBar/ToggleBar";
import styles from './DadosMedicosToggleBar.module.css'
import {useContext, useState} from "react";
import {EditDadosMedicos} from "../../../modals/EditAfiliadoModals/EditDadosMedicos/EditDadosMedicos";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";

export function DadosMedicosToggleBar() {
    const [dadosMedicos, setDadosMedicos] = useState();
    const {edit} = useContext(AfiliadoContext)

    return (
        <ToggleBar modal={edit && <EditDadosMedicos/>} edit={edit} apiService={getDadosMedicosAfiliado} setData={setDadosMedicos} label={"Dados Médicos"}>
            {dadosMedicos ?
                (
                <>
                    <div className={styles.colunaLateral}>
                        <Campo campo={"Tipo Sanguíneo"} lg texto={dadosMedicos.tipoSanguineo}/>
                        <Campo campo={"Tipo de Hemoglobina"} lg texto={dadosMedicos.tipoHemoglobina}/>
                    </div>
                    <Campo campo={"Alergias"} texto={dadosMedicos.alergias}/>
                    <Campo campo={"Condições Médicas"} texto={dadosMedicos.condicoesMedicas}/>
                </>
                ) : <p>Não está cadastrado nenhum dado médico</p>
            }
        </ToggleBar>
    )
}

function Campo({texto, campo, lg}) {
    return (
        <div className={styles.campo}>
            <h5>{campo}</h5>
            <div className={lg ? styles.textoLg : styles.texto}>
                <p>{texto ? texto : "-"}</p>
            </div>
        </div>
    )
}
