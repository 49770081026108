import styles from './CadastroAfiliadoForm.module.css'
import {useState} from "react";
import CardLayout from "../CardLayout/CardLayout";
import {IoArrowForwardCircle, IoCheckmarkCircleSharp} from "react-icons/io5";
import {Button} from "../CustomButtons/Button/Button";
import {ProgressBar} from "../ProgressBar/ProgressBar";

export function CadastroAfiliadoForm({ handleSubmit, proximoButton, title, step, children, isLoading }) {
    const [erros, setErros] = useState([])

    const handleErros = (erros) => {
        const formattedErros = Array.isArray(erros) ? erros.map(item => ({ campo: item.campo, mensagem: item.mensagem })) :
            [{ campo: null, mensagem: erros.mensagem }];
        setErros(formattedErros);
    }


    return (
        <div className={styles.containerCard}>
            <ProgressBar step={step}/>
            <CardLayout title={title} fullSize>
                <div>
                    <div className={styles.formSection}>
                        {children}
                    </div>
                    <div className={styles.footerSection}>
                        {erros && erros.map(({mensagem}) => <p className={styles.erros}>{mensagem}</p>)}
                        <div>
                            {proximoButton && <Button isLoading={isLoading} text={"Próximo"} backgroundColor={"var(--Azul-Primario)"}
                                         icon={<IoArrowForwardCircle size={22}/>}
                                         onClick={(event) => handleSubmit(event, false, handleErros)}/>}

                            <Button isLoading={isLoading} text={"Finalizar"} backgroundColor={"var(--laranja-principal)"}
                                    icon={<IoCheckmarkCircleSharp size={22}/>}
                                          onClick={(event) => handleSubmit(event, true, handleErros)}/>
                        </div>
                    </div>
                </div>
            </CardLayout>
        </div>
    )
}
