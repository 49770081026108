import {ConfigProvider, Select} from "antd";
import {useQuery} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import styles from './Filtro.module.css'

export function Filtro( {opcaoApiService, placeholder, onChange} ) {
    const { data, isLoading, error } = useQuery({
        queryKey: [placeholder],
        queryFn: () => opcaoApiService(),
    });

    useEffect(() => {
        if (data) {
            const options = data.map(option => ({
                label: option,
                value: option,
            }));
            setOptions(options);
        }
    }, [data]);

    const [options, setOptions] = useState([]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Select: {
                        colorTextPlaceholder: '#000000',
                        fontFamily: "Poppins",
                        fontWeightStrong: "400",
                        colorBorder: '#000000',
                        colorIcon: '#000000',
                        borderRadius: "12px",
                        optionFontSize: '16px',
                    },
                },
            }}
        >
            <>
                <Select
                    mode="multiple"
                    allowClear
                    loading={isLoading}
                    className={styles.selectFilter}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                    options={options}
                    notFoundContent={"Nenhum resultado"}
                />
            </>
        </ConfigProvider>

    );
}
