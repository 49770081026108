import {BarraDePesquisa} from "../../components/BarraDePesquisa/BarraDePesquisa";
import {Filtro} from "../../components/Filtro/Filtro";
import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import styles from './BuscaDocumentos.module.css'
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import {Button} from "../../components/CustomButtons/Button/Button";
import {IoAddCircleOutline} from "react-icons/io5";
import CadastroDocumentoModal from "../../modals/CadastroDocumentoModal/CadastroDocumentoModal";
import CardLayout from "../../components/CardLayout/CardLayout";
import {buscarDocumentos} from "../../services/apiService/documentoService";
import {getFormatos} from "../../services/apiService/opcoesServices";

export default function BuscaDocumentos() {
  const [searchData, setSearchData] = useState({ q: '', formatos: [] });
  const [formatos, setFormatos] = useState([]);
  const [q, setQ] = useState("");

  const { data: documentos } = useQuery({
    queryKey: ['documentos', searchData],
    queryFn: () => buscarDocumentos(searchData)
  });

  const handleSearch = () => {
    setSearchData({ q, formatos });
  }

  const botaoAddDocumento = <Button text={"Adicionar Documento"} icon={<IoAddCircleOutline size={30} />} fontSize={"20px"} backgroundColor={"var(--Azul-Primario)"} />
  const modalAddDocumento = <CadastroDocumentoModal button={botaoAddDocumento} />

  return (
    <div className={styles.buscaDocumentosContainer}>
      <BarraDePesquisa
        titulo={"Buscar Documentos"}
        onInputChange={setQ}
        placeholder={"Busque pelo nome do documento"}
        handleSubmit={handleSearch}
        adicionar={modalAddDocumento}
      >
        <Filtro onChange={setFormatos} placeholder={"Formato"} opcaoApiService={getFormatos} />
      </BarraDePesquisa>
      <CardLayout title={"Documentos"} fullSize={true}>
          {documentos && documentos.map((documento, index) => (
              <DocumentCard
                  key={index}
                  idDocumento={documento.id}
                  nomeArquivo={documento.nome}
                  formatoArquivo={documento.formato}
                  tamanhoArquivo={documento.tamanho}
                  linkDownload={documento.downloadLink}
                  corBackground={index % 2 === 0 ? "#EBEBEB": "#FFF"}
              />
          ))}
      </CardLayout>
    </div>
  );
}
