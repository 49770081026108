import styles from './TextoAuxiliar.module.css'

export function TextoAuxiliar( {upperTitulo, titulo, textoAuxiliar} ) {
    return (
        <div className={styles.textoAuxiliarContainer}>
            <div className={styles.titulo}>
                <h3>{upperTitulo}</h3>
                <h1>{titulo}</h1>
            </div>

            {textoAuxiliar &&
                <div className={styles.descricaoContainer}>
                    <p className={styles.descricao}>{textoAuxiliar}</p>
                </div>
            }
        </div>
    )
}
