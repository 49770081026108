import defaultImage from './../../assets/images/default-user-image.png'
import {FiUpload} from "react-icons/fi";
import styles from './ImageForm.module.css'
import {useId, useState} from "react";

export function ImageForm( {setImage} ) {
    const fileInputId = useId()
    const [imagePreview, setImagePreview] = useState(null);
    const [erro, setErro] = useState()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image')) {
                setErro("Selecione uma imagem!")
                setImagePreview(null)
                setImage()
                return;
            }
            setErro(null)

            setImage(file);

            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <div className={styles.formContainer}>
                <p>Imagem do Afiliado</p>
                <img src={imagePreview ? imagePreview : defaultImage} alt="Input de Imagem de perfil"/>
                <input
                    type={"file"}
                    name={"file"}
                    id={fileInputId}
                    accept={"image/*"}
                    onChange={handleImageChange}
                    style={{display: 'none'}}
                />
                <p className={styles.erro}>{erro}</p>
                <label htmlFor={fileInputId} className={styles.inputButton}>
                    Escolher imagem
                    <FiUpload strokeWidth={3}/>
                </label>
            </div>
        </div>

    )
}