import styles from "../AuthCard/AuthCard.module.css";
import logo from "../../assets/images/logo.jpg";
import React from "react";
import {LoadingOutlined} from "@ant-design/icons";

export function AuthCard( {children, titulo, btnText, handleSubmit, errors, isLoading} ) {

    return (
        <div className={styles.cardContainer}>
            <div className={styles.cardHeader}>
                <img src={logo} alt="Logo da ONG AFADFAL" className={styles.cardLogo}/>
                <h2 className={styles.cardTitle}>{titulo}</h2>
            </div>
            <div className={styles.cardBody}>
                {children}
                <div className={styles.erroContainer}>
                    {errors && <p>{errors}</p>}
                </div>
            </div>

            <div>
                <button className={styles.cardBtn} onClick={handleSubmit}>
                    {isLoading ? <LoadingOutlined style={{color: "#FFF", fontSize: "32px"}} spin/> : <p>{btnText}</p>}
                </button>
            </div>
        </div>
    )
}
