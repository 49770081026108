import styles from './CustomInput.module.css'
import {InputMask} from "primereact/inputmask";
import {useId} from "react";

export function CustomInput({key, label, tipo, onChange, placeholder, size, mask, value, name, required} ) {
    const idInput = useId();

    const inputProps = {
        key: key,
        value: value,
        id: idInput,
        name: name,
        placeholder: placeholder,
        onChange: onChange,
        className: `${styles.inputText} ${size ? styles[size] : styles.md}`,
        type: tipo ? tipo : "text",
        required: !!required,
    };

    if (mask) {
        inputProps.mask = mask
    }

    return (
        <div className={styles.inputContainer}>
            <label htmlFor={idInput}>
                {label}{required && <span style={{color: "red"}}>*</span>}
            </label>
            {mask ? (
                    <InputMask {...inputProps}></InputMask>
                ) : (
                    <input {...inputProps}/>
                )
            }

        </div>
    );
}
