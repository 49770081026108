import {Link} from "react-router-dom";
import styles from "./Navbar.module.css"
import moment from "moment"
import logo from '../../assets/images/logo.jpg';
import DropdownCadastroButton from "./CadastroDropdown/DropdownCadastroButton";
import NavbarLink from "./NavbarLink/NavbarLink";
import AdminDropdown from "./AdminDropdown/AdminDropdown";
import React from "react";
import MenuDropdownMobile from "./MobileDropdown/MenuDropdownMobile";


export default function Navbar() {

    return (
        <>
            <nav className={styles.navbar}>
                <MenuDropdownMobile className={styles.menuDropdownMobile}/>
                <Link to="/" className={styles.logoSection}>
                    <img src={logo} alt="Logo da ONG AFADFAL"/>
                    <h1>AFADFAL</h1>
                </Link>
                <div className={styles.menuSection}>
                    <div className={styles.linksSection}>
                       <NavbarLink to="/" text="Página Principal" />
                       <DropdownCadastroButton/>
                       <NavbarLink to="/afiliados" text="Afiliados" />
                       <NavbarLink to="/documentos" text="Documentos" />
                    </div>
                    <div>
                       <b>{moment().format("DD/MM/yyyy")}</b>
                    </div>
                    <AdminDropdown className={styles.adminSection} />
                </div>
            </nav>

        </>
    )
}