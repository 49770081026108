import {Outlet} from "react-router-dom";
import styles from './Containers.module.css'
import onda from '../../assets/images/onda.png';

// Container das páginas de autenticação
export default function AuthContainer( ) {
    return (
        <div className={styles.authContainer}>
            <main className={styles.authContent}>
                <Outlet/>
            </main>
            <footer>
                <img src={onda} className={styles.footerOndulacao}/>
            </footer>
        </div>
    )
}