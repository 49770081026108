import {useContext, useState} from "react";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";
import {EditAfiliadoModal} from "../EditAfiliadoModal";
import {cadastrarDadosDemograficosAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {DadosDemograficosForm} from "../../../components/AfiliadoForms/DadosDemograficosForm/DadosDemograficosForm";

export function EditDadosDemograficos() {
    const { id } = useContext(AfiliadoContext)
    const [formData, setFormData] = useState()

    return (
        <EditAfiliadoModal title={"Editar Dados Demográficos"} formData={formData} submitApiService={cadastrarDadosDemograficosAfiliado} size={"6xl"}>
            <DadosDemograficosForm edit id={id} setFormDataChange={(novoFormData) => setFormData(novoFormData)}/>
        </EditAfiliadoModal>
    )
}
