import {useContext, useState} from "react";
import {EditAfiliadoModal} from "../EditAfiliadoModal";
import {cadastrarDadosMedicosAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {DadosMedicosForm} from "../../../components/AfiliadoForms/DadosMedicosForm/DadosMedicosForm";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";

export function EditDadosMedicos() {
    const { id } = useContext(AfiliadoContext)
    const [formData, setFormData] = useState()

    return (
        <EditAfiliadoModal title={"Editar Dados Médicos"} formData={formData} submitApiService={cadastrarDadosMedicosAfiliado} size={"6xl"}>
            <DadosMedicosForm id={id} setFormDataChange={(novoFormData) => setFormData(novoFormData)} edit/>
        </EditAfiliadoModal>
    )
}
