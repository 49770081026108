import styles from "./CardAfiliado.module.css";
import imagem from "../../assets/images/default-user-image.png";
import {Link} from "react-router-dom";

const Card = ({ imagemPerfil, nome, idade, tipoSanguineo, id }) => {
  return (
    <div key={id} className={styles.cardContainer}>
        <div className={styles.imagemPerfil}>
            {imagemPerfil ? (
                <img src={`data:image/jpeg;base64,${imagemPerfil}`}
                     alt={`Imagem de perfil de ${nome}`}/>
            ) : <img src={imagem} alt={"Imagem padrão cinza de perfil"}/>
            }
        </div>
        <div className={styles.infoSection}>
            <div className={styles.dadosSection}>
                <h5>{nome}</h5>

                <p>{idade} anos{tipoSanguineo && `, ${tipoSanguineo}`}</p>
            </div>
            <div className={styles.btnSection}>
                <Link to={`${id}`}>Ver Detalhes</Link>
            </div>
        </div>
    </div>
  );
};

export default Card;