import {LuSearch} from "react-icons/lu";
import styles from './BarraDePesquisa.module.css'
import {Button} from "../CustomButtons/Button/Button";

export function BarraDePesquisa( {handleSubmit, placeholder, titulo, adicionar, children, onInputChange} ) {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className={styles.secaoPesquisa}>
            <div className={styles.secaoInput}>
                <h2 className={styles.titulo}>{titulo}</h2>
                <input onKeyDown={handleKeyDown} className={styles.barraPesquisa} onChange={(e) => onInputChange(e.target.value)} placeholder={placeholder}/>
                <Button icon={<LuSearch size={30}/>} backgroundColor={"var(--laranja-principal)"} onClick={handleSubmit}/>
            </div>
            <div className={styles.secaoAuxiliar}>
                <div className={styles.containerFiltros}>
                    {children}
                </div>
                <div className={styles.containerBotao}>
                    {adicionar}
                </div>
            </div>
        </div>
    )
}
