import * as React from "react";
import {Link} from "react-router-dom";
import styles from './LinksRapidos.module.css'
import CardLayout from "../CardLayout/CardLayout";
import {AddUser, FileAdditionOne, FolderSearch, Search} from "@icon-park/react";
import CadastroDocumentoModal from "../../modals/CadastroDocumentoModal/CadastroDocumentoModal";


export default function LinksRapidos() {
    return (
        <CardLayout title="Links Rápidos" customClass={styles.layoutLinksRapidos}>
            <div className={styles.LinksRapidos}>
                <BotaoRapido
                    icon={<AddUser theme="outline" strokeWidth={"4.5"}/>}
                    text={"Cadastro Afiliados"}
                    link={"/afiliados/cadastro"}
                />
                <CadastroDocumentoModal
                    button={
                        <BotaoRapido
                            icon={ <FileAdditionOne theme="outline"/> }
                            text={"Cadastro Documentos"}
                        />
                    }
                />

                <BotaoRapido
                    icon={<Search theme="outline"/>}
                    text={"Busca Afiliados"}
                    link={"/afiliados"}
                />
                <BotaoRapido
                    icon={<FolderSearch theme="outline"/>}
                    text={"Busca Documentos"}
                    link={"/documentos"}
                />

            </div>
        </CardLayout>
    )
}

function BotaoRapido ({icon, text, link, onClick}) {
    return (
        <Link onClick={onClick} to={link} className={styles.botaoRapido}>
            {icon}
            <p>{text}</p>
        </Link>
    )
}