import React, {useContext, useState} from 'react';
import {cadastrarNovoAdmin} from '../../services/apiService/apiServices';
import {AuthCard} from "../AuthCard/AuthCard";
import {AuthInput} from "../AuthInput/AuthInput";
import {AuthContext} from "../../context/AuthContext";
import {useMutation} from "@tanstack/react-query";

export function AuthCardCadastro() {
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const { handleLogin } = useContext(AuthContext);
  const [erros, setErros] = useState()

    const mutation = useMutation({
        mutationFn: async ({ usuario, senha }) => {
            await cadastrarNovoAdmin({ usuario, senha });
            await handleLogin({ usuario, senha });
        },
        onError: () => {
            setErros('Erro ao realizar cadastro');
        },
    });

    const handleCadastro = () => {
        if (senha !== confirmarSenha) {
            setErros('Senhas não coincidem');
            return;
        }
        setErros('');
        mutation.mutate({ usuario, senha });
    };


    return (
      <AuthCard
          titulo={"Cadastre um novo administrador"}
          btnText={"Cadastrar"}
          handleSubmit={handleCadastro}
          errors={erros}
          isLoading={mutation.isPending}
      >
        <AuthInput
            type="text"
            placeholder="Digite seu usuário"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
        />
        <AuthInput
            type="password"
            placeholder="Digite sua senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
        />
        <AuthInput
            type="password"
            placeholder="Confirme sua senha"
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
        />
      </AuthCard>
  );
};

export default AuthCardCadastro;