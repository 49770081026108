import React, {useId} from 'react';
import styles from './CustomTextArea.module.css';

export function CustomTextArea({ key, label, onChange, placeholder, value, name, required }) {
    const idTextArea = useId();

    const textAreaProps = {
        key: key,
        value: value,
        id: idTextArea,
        name: name,
        placeholder: placeholder,
        onChange: onChange,
        className: styles.textArea,
        required: !!required,
    };

    return (
        <div className={styles.textAreaContainer}>
            <label htmlFor={idTextArea}>
                {label}{required && <span style={{ color: "red" }}>*</span>}
            </label>
            <textarea {...textAreaProps} />
        </div>
    );
}
