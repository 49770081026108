import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {cadastrarNovoAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {CadastroAfiliadoForm} from "../../../components/CadastroAfiliadoForm/CadastroAfiliadoForm";
import {DadosPessoaisForm} from "../../../components/AfiliadoForms/DadosPessoaisForm/DadosPessoaisForm";
import {useMutation} from "@tanstack/react-query";


export function CadastroDadosPessoais() {
    const [imagemPerfil, setImagemPerfil] = useState();
    const [formData, setFormData] = useState();
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: ({form}) => cadastrarNovoAfiliado(form),
        onSuccess: (data, {isFinalizar}) => {
            const afiliadoId = data.id;
            if (isFinalizar === true) {
                navigate(`/afiliados/${afiliadoId}`);
            } else {
                navigate(`/afiliados/${afiliadoId}/endereco`);
            }
        },
        onError: (error, {handleErro}) => {
            if (error.response.data) handleErro(error.response.data)
        }
    })

    const handleSubmit = async (event, isFinalizar, handleErro) => {
        event.preventDefault();
        const form = new FormData();
        form.append("imagemPerfil", imagemPerfil);
        form.append("form-data", new Blob([JSON.stringify(formData)], { type: "application/json" }));

        mutation.mutate({form, isFinalizar, handleErro})
    };


    return (
        <CadastroAfiliadoForm isLoading={mutation.isPending} handleSubmit={handleSubmit} step={1} title={"Cadastro - Dados Pessoais"} proximoButton>
            <DadosPessoaisForm setFormDataChange={(novoFormData) => setFormData(novoFormData)} setImagemPerfilChange={(imagemPerfil) => setImagemPerfil(imagemPerfil)}/>
        </CadastroAfiliadoForm>
    )
}
