import React, {useContext, useState} from "react";
import {Button} from "../../components/CustomButtons/Button/Button";
import {CustomModal} from "../CustomModal/CustomModal";
import {IoArrowBackCircle, IoCheckmarkCircleSharp} from "react-icons/io5";
import {useDisclosure} from "@chakra-ui/react";
import {SuccessModal} from "../SuccessModal/SuccessModal";
import {FiEdit} from "react-icons/fi";
import {AfiliadoContext} from "../../pages/DetalheAfiliado/DetalheAfiliado";


export function EditAfiliadoModal({submitApiService, children, formData, size, title}) {
    const { id } = useContext(AfiliadoContext)

    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
    const { isOpen: isOpenSuccess, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
    const [erro, setErro] =  useState([])

    const handleErro = (erro) => {
        if (Array.isArray(erro)) {
            const erros = erro.map(item => ({ campo: item.campo, mensagem: item.mensagem }));
            setErro(erros);
        } else {
            setErro([{ campo: null, mensagem: erro.mensagem }]);
        }
    }

    const handleSubmit = async () => {

        try {
            await submitApiService(formData, id);

            onCloseModal();
            onOpenSuccess();
        } catch (error) {
            if (error.response.data) handleErro(error.response.data)
        }
    };

    const footer = (
        <>
            <Button text={"Sair"} backgroundColor={"var(--cinza-secundario)"}
                    icon={<IoArrowBackCircle size={22} />}
                    onClick={onCloseModal} />
            <Button text={"Salvar Alterações"} backgroundColor={"var(--laranja-principal)"}
                    icon={<IoCheckmarkCircleSharp size={22} />}
                    onClick={handleSubmit} />
        </>
    )

    return (
        <>
            <Button icon={<FiEdit size={15} />} backgroundColor={"var(--laranja-principal)"} onClick={onOpenModal}/>

            <CustomModal
                size={size ? size : "2xl"}
                onClose={onCloseModal}
                isOpen={isOpenModal}
                title={title}
                error={erro}
                footer={footer}
            >
                {children}
            </CustomModal>

            <SuccessModal text={"Dado alterado com sucesso!"} isOpen={isOpenSuccess} onClose={onCloseSuccess}/>
        </>
    )
}
