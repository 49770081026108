import styles from './DetalheAfiliado.module.css'
import {useParams} from "react-router-dom";
import {SecaoDadosPessoaisAfiliado} from "../../components/DadosPessoaisAfiliado/SecaoDadosPessoaisAfiliado";
import {useQuery} from "@tanstack/react-query";
import {getDadosPessoaisAfiliado} from "../../services/apiService/dadosAfiliadoService";
import {Loading} from "../../components/Loading/Loading";
import {DadosAuxiliaresAfiliado} from "../../components/DadosAuxiliaresAfiliado/DadosAuxiliaresAfiliado";
import {createContext} from "react";
import {SecaoDocumento} from "../../components/SecaoDocumentos/SecaoDocumentos";
import {DadosPessoais} from "../../components/DadosPessoaisAfiliado/DadosPessoais/DadosPessoais";

export const AfiliadoContext = createContext();

export function DetalheAfiliado() {
    const { id } = useParams();

    const {data: dadosPessoais, isLoading} = useQuery({
        queryFn: () => getDadosPessoaisAfiliado(id),
        queryKey: [id],
    })

    return (
        <AfiliadoContext.Provider value={{id}}>
            {
                isLoading ? <Loading/> :
                    <div className={styles.detalheContainer}>
                        <div className={styles.colunaLateral}>
                            <SecaoDadosPessoaisAfiliado dadosPessoais={dadosPessoais}>
                                <DadosPessoais/>
                            </SecaoDadosPessoaisAfiliado>

                            <SecaoDocumento documentos={dadosPessoais.documentos}/>
                        </div>

                        <div className={styles.colunaDadosAuxiliares}>
                            <DadosAuxiliaresAfiliado/>
                        </div>
                    </div>
            }
        </AfiliadoContext.Provider>
    )
}