import {useDisclosure,} from '@chakra-ui/react'
import {cloneElement, useState} from "react";
import {IoCheckmarkCircle} from "react-icons/io5";
import {CustomModal} from "../CustomModal/CustomModal";
import {SuccessModal} from "../SuccessModal/SuccessModal";
import {Button} from "../../components/CustomButtons/Button/Button";
import {InputDocumento} from "../../components/InputDocumento/InputDocumento";
import {uploadDocumentoAfiliado} from "../../services/apiService/cadastroAfiliadoService";
import {uploadDocumento} from "../../services/apiService/documentoService";
import {useMutation} from "@tanstack/react-query";

// Se tiver id, cadastra documento do afiliado
// Se não tiver id, cadastra documento geral
export default function CadastroDocumentoModal( {button, id} ) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenSuccess, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
    const [documento, setDocumento] = useState(null)
    const [uploadError, setUploadError] = useState([]);

    const handleSuccess = () => {
        onClose();
        onOpenSuccess();
    };

    const handleError = () => {
        setUploadError([{ campo: null, mensagem: "Erro ao fazer upload do documento. Por favor, tente novamente." }]);
    };

    const mutation = useMutation({
        mutationFn: (formData) => (id ? uploadDocumentoAfiliado(formData, id) : uploadDocumento(formData)),
        onSuccess: handleSuccess,
        onError: handleError
    });

    const handleUpload = async () => {
        if (!documento) {
            setUploadError([{ campo: null, mensagem: "Selecione um documento!" }]);
            return;
        }

        const formData = new FormData();
        formData.append("documento", documento);
        mutation.mutate(formData);
    };

    const handleSuccessModalClose = () => {
        onCloseSuccess();
        window.location.reload();
    };

    // Componente de rodapé do modal
    const footerButton = (
        <Button
            isLoading={mutation.isPending}
            text={"Salvar Documento"}
            icon={<IoCheckmarkCircle size={20} />}
            onClick={handleUpload}
            backgroundColor={`var(--laranja-principal)`}
        />
    );

    // Componente de modal de cadastro de documento
    const documentoModal = (
        <CustomModal
            isOpen={isOpen}
            onClose={onClose}
            title={id ? "Cadastro de documento de afiliado" : "Cadastro de documento"}
            error={uploadError}
            footer={footerButton}
        >
            <InputDocumento onChange={setDocumento} />
        </CustomModal>
    );

    // Renderização dos componentes
    return (
        <>
            {cloneElement(button, { onClick: onOpen })}

            {documentoModal}
            <SuccessModal isOpen={isOpenSuccess} onClose={handleSuccessModalClose} text={"O documento foi cadastrado com sucesso!"} />
        </>
    );
}