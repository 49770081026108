import {useContext, useState} from "react";
import {EditAfiliadoModal} from "../EditAfiliadoModal";
import {ContatosEmergenciaForm} from "../../../components/AfiliadoForms/ContatosEmergenciaForm/ContatosEmergenciaForm";
import {editarContatosEmergencia} from "../../../services/apiService/editarAfiliadoService";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";

export function EditContatosEmergencia() {
    const { id } = useContext(AfiliadoContext)
    const [formData, setFormData] = useState()

    return (
        <EditAfiliadoModal title={"Editar Contatos de Emergência"} formData={formData} submitApiService={editarContatosEmergencia} size={"6xl"}>
            <ContatosEmergenciaForm id={id} setFormDataChange={(novoFormData) => setFormData(novoFormData)} edit/>
        </EditAfiliadoModal>
    )
}
