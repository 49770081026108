import styles from './SuccessModal.module.css'
import {CustomModal} from "../CustomModal/CustomModal";
import {CheckCircleOutlined} from "@ant-design/icons";

export function SuccessModal( {isOpen, onClose, text} ) {

    return (
        <CustomModal
            isOpen={isOpen}
            onClose={onClose}
            title={"Sucesso!"}
        >
            <div className={styles.modalBody}>
                <CheckCircleOutlined/>
                <h1>{text}</h1>
            </div>

        </CustomModal>
    )
}
