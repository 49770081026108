import CardLayout from "../CardLayout/CardLayout";
import styles from './DadosAuxiliaresAfiliado.module.css'
import {EnderecoToggleBar} from "./EnderecoToggleBar/EnderecoToggleBar";
import {DadosMedicosToggleBar} from "./DadosMedicosToggleBar/DadosMedicosToggleBar";
import {ContatosToggleBar} from "./ContatosToggleBar/ContatosToggleBar";
import {DadosDemograficosToggleBar} from "./DadosDemograficosToggleBar/DadosDemograficosToggleBar";
import {InformacoesToggleBar} from "./InformacoesToggleBar/InformacoesToggleBar";

export function DadosAuxiliaresAfiliado() {

    return (
        <CardLayout fullSize title={"Dados Auxiliares"}>
            <div className={styles.dadosContainer}>
                <EnderecoToggleBar/>
                <DadosMedicosToggleBar/>
                <DadosDemograficosToggleBar/>
                <ContatosToggleBar/>
                <InformacoesToggleBar/>
            </div>


        </CardLayout>
    )
}
