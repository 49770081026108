import {CloudUploadOutlined} from "@ant-design/icons";
import {Upload} from "antd";


const { Dragger } = Upload;
export function InputDocumento({ onChange } ) {

    return (
        <Dragger name={"documento"} maxCount={1} onChange={(info) => onChange(info.file)} beforeUpload={() => false}>
            <p className="ant-upload-drag-icon">
                <CloudUploadOutlined style={{color: "#a69f9b"}}/>
            </p>
            <p className="ant-upload-text">Escolha um arquivo ou arraste e solte aqui</p>
            <p className="ant-upload-hint">
                Arquivos aceitos até 25 MB.
            </p>
        </Dragger>
    )
}


