import * as React from 'react'
import {Dropdown} from 'antd';
import {IoIosArrowDropdown} from "react-icons/io";
import {CustomItemDropdown} from "../ItemDropdown/CustomItemDropdown";
import {AddUser, FileAdditionOne} from "@icon-park/react";
import CadastroDocumentoModal from "../../../modals/CadastroDocumentoModal/CadastroDocumentoModal";


export default function DropdownCadastroButton() {

    const items = [
        {
            key: '1',
            label: (
                <CadastroDocumentoModal
                    button={
                        <CustomItemDropdown
                            text={"Cadastro de Documentos"}
                            icon={<FileAdditionOne theme="outline"/>}
                        />
                    }
                />
            ),
        },
        {
            key: '2',
            label: ( <CustomItemDropdown text={"Cadastro de Afiliados"} to={"/afiliados/cadastro"} icon={<AddUser theme="outline" strokeWidth={"4.5"}/>} /> ),
        },
    ];

    return (
        <Dropdown menu={{ items }} placement="bottom">
            <a onClick={(e) => e.preventDefault()}>
                Cadastros
                <IoIosArrowDropdown />
            </a>
        </Dropdown>
    );
};