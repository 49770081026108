import {useEffect, useState} from "react";
import styles from "../AfiliadoForms.module.css"
import {CustomTextArea} from "../../CustomInput/CustomTextArea";
import {CustomOptionInput} from "../../CustomInput/CustomOptionInput";
import {getTiposHemoglobinas, getTiposSanguineos} from "../../../services/apiService/opcoesServices";
import {getDadosMedicosAfiliado} from "../../../services/apiService/dadosAfiliadoService";

export function DadosMedicosForm({edit, setFormDataChange, id}) {

    const [formData, setFormData] = useState({
        condicoesMedicas: null,
        alergias: null,
        tipoSanguineo: null,
        tipoHemoglobina: null,
    });

    useEffect(() => {
        const fetchDadosMedicos = async (id) => {
            try {
                const response = await getDadosMedicosAfiliado(id)

                setFormData(response)
            }
            catch (error) {
                console.log(error)
            }
        }

        if (edit) {
            fetchDadosMedicos(id)
        }

    }, [edit, id]);

    const formTextInputs = [
        {
            name: "condicoesMedicas",
            label: "Condições Médicas",
            value: formData.condicoesMedicas,
            placeholder: "",
            size: "lg",
        },
        {
            name: "alergias",
            label: "Alergias",
            value: formData.alergias,
            placeholder: "",
            size: "md",
        },
    ];

    const formOptionInputs = [
        {
            name: "tipoSanguineo",
            label: "Tipo Sanguíneo",
            value: formData.tipoSanguineo,
            placeholder: "Selecione o seu tipo sanguíneo",
            optionService: getTiposSanguineos,
            required: true,
        },
        {
            name: "tipoHemoglobina",
            label: "Tipo de Hemoglobina",
            value: formData.tipoHemoglobina,
            placeholder: "Selecione o seu tipo de hemoglobina",
            optionService: getTiposHemoglobinas,
            required: true,
        },
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
            ...formData,
            [name]: value === "" ? null : value,
        };
        setFormData(updatedFormData);
        setFormDataChange(updatedFormData)
    };

    return (
        <>
            <div className={styles.optionColumn}>
                {formOptionInputs.map((input, index) => <CustomOptionInput
                    key={index}
                    onChange={handleInputChange}
                    {...input}
                />)}
            </div>
            {formTextInputs.map((input, index) =>
                <CustomTextArea
                    key={index}
                    onChange={handleInputChange}
                    {...input}
                />)}
        </>
    )
}