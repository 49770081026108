import {axiosInstance} from "../axiosInstance";

export const getDocumentos = async () => {
    const response = await axiosInstance.get("/documentos")
    return response.data
}

export const buscarDocumentos = async (dadosPesquisa) => {
    const response = await axiosInstance.get("/documentos/buscar", {
        params: dadosPesquisa,
        paramsSerializer: {indexes: null}
    });
    return response.data;
}

export const uploadDocumento = async (documento) => {
    const response = await axiosInstance.post("/documentos", documento,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}

export const deleteDocumento = async (idDocumento) => {
    const response = await axiosInstance.delete(`/documentos/${idDocumento}`);
    return response.data;
}

