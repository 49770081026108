import styles from "./CustomInput.module.css";
import {useQuery} from "@tanstack/react-query";
import {useEffect, useId, useState} from "react";

export function CustomOptionInput({key, onChange, label, placeholder, value, optionService, name, required }) {
    const selectId = useId();

    const { data } = useQuery({
        queryKey: label,
        queryFn: () => optionService()
    });

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (data) {
            const options = data.map(option => ({
                label: option,
                value: option,
            }));
            setOptions(options);
        }
    }, [data]);

    return (
        <div className={styles.inputContainer}>
            <label htmlFor={selectId}>
                {label}{required && <span style={{color: "red"}}>*</span>}
            </label>
            <select key={key} id={selectId} name={name} value={value} onChange={onChange}
                    className={styles.inputText} required={!!required}>
                <option disabled selected value={placeholder}>{placeholder}</option>
                {options && options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    );
}
