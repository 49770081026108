import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {cadastrarDadosMedicosAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {CadastroAfiliadoForm} from "../../../components/CadastroAfiliadoForm/CadastroAfiliadoForm";
import {DadosMedicosForm} from "../../../components/AfiliadoForms/DadosMedicosForm/DadosMedicosForm";
import {useMutation} from "@tanstack/react-query";

export function CadastroDadosMedicos() {

    const { id } = useParams();

    const [formData, setFormData] = useState();
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: ({formData}) => cadastrarDadosMedicosAfiliado(formData, id),
        onSuccess: (data, { isFinalizar}) => {
            if (isFinalizar === true) {
                navigate(`/afiliados/${id}`);
            } else {
                navigate(`/afiliados/${id}/contatos-emergencia`);
            }
        },
        onError: (error, { handleErro }) => {
            if (error.response.data) handleErro(error.response.data);
        }
    });

    const handleSubmit = (event, isFinalizar, handleErro) => {
        event.preventDefault();
        mutation.mutate({ formData, isFinalizar, handleErro });
    };

    return (
        <CadastroAfiliadoForm isLoading={mutation.isPending} step={3} title={"Cadastro - Dados Médicos"} handleSubmit={handleSubmit} proximoButton>
            <DadosMedicosForm setFormDataChange={(novoFormData) => setFormData(novoFormData)}/>
        </CadastroAfiliadoForm>
    )
}