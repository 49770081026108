import {useEffect, useState} from "react";
import {CustomInput} from "../../CustomInput/CustomInput";
import axios from "axios";
import {getDadosEnderecoAfiliado} from "../../../services/apiService/dadosAfiliadoService";


export function DadosdeEnderecoForm({edit, setFormDataChange, id}) {

    const [formData, setFormData] = useState({
        cep: null,
        logradouro: "",
        bairro: "",
        estado: "",
        cidade: "",
        complemento: "",
        numero: "",
    });

    useEffect(() => {
        const fetchEnderecoData = async (id) => {
            try {
                const response = await getDadosEnderecoAfiliado(id);
                setFormData(response);
            } catch (error) {
                console.log(error);
            }
        };

        if (edit) {
            fetchEnderecoData(id)
        }

    }, [edit, id]);

    const checkCEP = async (e) => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${e.target.value}/json/`);
            const responseData = response.data;
            setFormData((prevFormData) => ({
                ...prevFormData,
                cep: e.target.value,
                logradouro: responseData.logradouro,
                bairro: responseData.bairro,
                estado: responseData.uf,
                cidade: responseData.localidade,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    const formTextInputs = [
        {
            name: "cep",
            label: "CEP",
            value: formData.cep,
            placeholder: "XXXXX-XXX",
            mask: "99999-999",
            size: "md",
            required: true,
        },
        {
            name: "logradouro",
            label: "Logradouro",
            value: formData.logradouro,
            placeholder: "Digite seu endereço",
            size: "lg",
            required: true,
        },
        {
            name: "bairro",
            label: "Bairro",
            value: formData.bairro,
            placeholder: "Digite seu Bairro",
            size: "sm",
            required: true,
        },
        {
            name: "numero",
            label: "Número",
            value: formData.numero,
            size: "sm",
        },
        {
            name: "complemento",
            label: "Complemento",
            value: formData.complemento,
            placeholder: "Ex: Casa, apartamento...",
            size: "lg",
        },
        {
            name: "cidade",
            label: "Cidade",
            value: formData.cidade,
            placeholder: "Digite a Cidade",
            size: "sm",
            required: true,
        },
        {
            name: "estado",
            label: "Estado",
            value: formData.estado,
            placeholder: "Digite o Estado",
            size: "sm",
            required: true,
        },
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "cep") {
            checkCEP(event)
        }
        const updatedFormData = {
            ...formData,
            [name]: value === "" ? null : value,
        };
        setFormData(updatedFormData);
        setFormDataChange(updatedFormData);
    };
    


    return (
         <>
             {formTextInputs.map((input, index) =>
                 <CustomInput
                     key={index}
                     onChange={handleInputChange}
                     {...input}
                 />)}
         </>
    )
}
