import React from "react";
import {Dropdown} from 'antd';
import {IoLogOutOutline, IoMenu} from "react-icons/io5";
import {CustomItemDropdown} from "../ItemDropdown/CustomItemDropdown";
import {AddUser, FileAdditionOne, FolderSearch, Home, Search} from "@icon-park/react";
import {MdOutlineAdminPanelSettings} from "react-icons/md";

// Pagina Principal, Cadastros, Afiliados, Documentos
export default function MenuDropdownMobile({ className }) {

    const items = [
        {
            key: '0',
            type: 'group',
            label: 'Página Principal',
            children: [
                {
                    key: '0-1',
                    label: (
                        <CustomItemDropdown
                            icon={ <Home theme="outline"/> }
                            text={"Página Principal"}
                            to={"/"}
                        />
                    ),
                }
            ]
        },
        {
            key: '1',
            type: 'group',
            label: 'Afiliados',
            children: [
                {
                    key: '1-1',
                    label: (
                        <CustomItemDropdown
                            icon={ <AddUser theme="outline" strokeWidth={"4.5"}/> }
                            text={"Cadastro Afiliados"}
                            to={"/afiliados/cadastro"}
                        />
                    ),
                },
                {
                    key: '1-2',
                    label: (
                        <CustomItemDropdown
                            icon={ <Search theme="outline"/> }
                            text={"Busca Afiliados"}
                            to={"/afiliados"}
                        />
                    ),
                },
            ]
        },
        {
            key: '2',
            type: 'group',
            label: 'Documentos',
            children: [
                {
                    key: '2-1',
                    label: (
                        <CustomItemDropdown
                            icon={ <FileAdditionOne theme="outline"/> }
                            text={"Cadastro Documentos"}
                            to={"/"}
                        />
                    ),
                },
                {
                    key: '2-2',
                    label: (
                        <CustomItemDropdown
                            icon={ <FolderSearch theme="outline"/> }
                            text={"Busca Documentos"}
                            to={"/documentos"}
                        />
                    ),
                },
            ]
        },
        {
            key: '3',
            type: 'group',
            label: 'Administrador',
            children: [
                {
                    key: '3-1',
                    label: (
                        <CustomItemDropdown
                            icon={<MdOutlineAdminPanelSettings/>}
                            text={"Novo Administrador"}
                            to={"/auth/cadastro"}
                        />
                    ),
                },
                {
                    key: '3-2',
                    label: (
                        <CustomItemDropdown
                            icon={<IoLogOutOutline/>}
                            text={"Logout"}
                            to={"/auth/login"}
                        />
                    ),
                },
            ]
        },
    ];

    return (
        <div className={className}>
            <Dropdown menu={{items}} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <IoMenu size={"25"} />
                </a>
            </Dropdown>
        </div>
    );
}






