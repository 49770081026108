import {useContext, useState} from "react";
import {EditAfiliadoModal} from "../EditAfiliadoModal";
import {editarHistoricoInformacoes} from "../../../services/apiService/editarAfiliadoService";
import {
    HistoricoInformacoesForm
} from "../../../components/AfiliadoForms/HistoricoInformacoesForm/HistoricoInformacoesForm";
import {AfiliadoContext} from "../../../pages/DetalheAfiliado/DetalheAfiliado";

export function EditHistoricoInformacoes() {
    const { id } = useContext(AfiliadoContext)
    const [formData, setFormData] = useState()

    return (
        <EditAfiliadoModal title={"Editar Histórico de Informações"} formData={formData} submitApiService={editarHistoricoInformacoes} size={"3xl"}>
            <HistoricoInformacoesForm id={id} setFormDataChange={(novoFormData) => setFormData(novoFormData)} edit/>
        </EditAfiliadoModal>
    )
}