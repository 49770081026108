import styles from '../../components/DocumentCard/DocumentCard.module.css';
import {MdOutlineFileDownload} from "react-icons/md";
import {CustomApagarModal} from '../../modals/CustomApagarModal/CustomApagarModal';
import {deleteDocumento} from '../../services/apiService/documentoService';
import {Button} from '../CustomButtons/Button/Button';
import {FiTrash} from 'react-icons/fi';

export default function DocumentCard({ idDocumento, nomeArquivo, formatoArquivo, tamanhoArquivo, linkDownload, corBackground }) {

    const customApagarModal = (
        <CustomApagarModal
            button={<Button icon={<FiTrash size={25} color="red"/>} />}
            apiService={() => deleteDocumento(idDocumento)}
            text={"Deseja apagar esse documento?"}
            sendTo={`/documentos`}
        />
    )

    return (
        
        <div className={styles.card} style={{ backgroundColor: corBackground }}>
            <div className={`${styles.campo} ${styles.campoNome}`}>
                <p className={styles.tituloCampo}>Nome </p>
                <h4 className={styles.dadosCampo}>{nomeArquivo}</h4>
            </div>
            <div className={styles.campo}>
                <p className={styles.tituloCampo}>Formato </p>
                <h4 className={styles.dadosCampo}>{formatoArquivo}</h4>
            </div>
            <div className={styles.campo}>
                <p className={styles.tituloCampo}>Tamanho </p>
                <h4 className={styles.dadosCampo}>{tamanhoArquivo}</h4>
            </div>
            <a href={linkDownload} target={"_blank"} rel="noreferrer" className={styles.downloadButton}>
                Baixar
                <MdOutlineFileDownload theme="outline" size="30"/>
            </a>
            {customApagarModal}

        </div>
    );
};
