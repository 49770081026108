import styles from './CardLayout.module.css'

export default function CardLayout({title, customClass, children, fullSize}) {
    return (
        <section className={`${styles.cardLayout} ${customClass} `} style={fullSize && {width:"100%"} }>
            {title && <h2 className={styles.cardTitle}>{title}</h2>}
            <div>
                {children}
            </div>
        </section>
    )
}