import React from 'react';
import AuthCardCadastro from '../../components/AuthCardCadastro/AuthCardCadastro';
import {TextoAuxiliar} from "../../components/TextoAuxiliar/TextoAuxiliar";

export default function CadastroAdmin() {
    return (
        <>
            <AuthCardCadastro/>
            <TextoAuxiliar
                upperTitulo={"Cadastro de"}
                titulo={"Administrador"}
            />
        </>
    )
}