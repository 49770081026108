import {IoIosArrowDown} from "react-icons/io";
import styles from './ToggleBar.module.css'
import {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {Loading} from "../Loading/Loading";
import {AfiliadoContext} from "../../pages/DetalheAfiliado/DetalheAfiliado";

export default function ToggleBar({ label, children, modal, apiService, setData, edit }) {
    const { id } = useContext(AfiliadoContext);
    const [open, setOpen] = useState(false);
    const [isRotated, setIsRotated] = useState(false);

    const { data, isLoading } = useQuery({
        queryKey: [label, id],
        queryFn: () => apiService(id),
        enabled: open,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data) {
            setData(data);
        }
    }, [data, setData]);

    function toggle() {
        setOpen(!open);
        setIsRotated(!isRotated);
    }

    return (
        <div className={styles.toggleBar}>
            <div className={styles.headerSection}>
                <h3>{label}</h3>
                <div className={styles.rightSection}>
                    {edit ? modal : (
                        <>
                            {modal}
                            <div className={styles.toggleButton}><IoIosArrowDown onClick={toggle} className={`${isRotated ? styles.rotated : null}`} strokeWidth={2.5} size={25} /></div>
                        </>
                    )}
                </div>
            </div>

            {open && (
                <div className={styles.dataSection}>
                    {isLoading ? <Loading /> : children}
                </div>
            )}
        </div>
    );
}