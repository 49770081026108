import React, {createContext} from 'react';
import useAuth from "./hooks/UseAuth";

const AuthContext = createContext();

function AuthProvider({ children }) {
    const { authenticated, loading, handleLogin, handleLogout } = useAuth();

    return (
        <AuthContext.Provider value={{ loading, authenticated, handleLogin, handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };