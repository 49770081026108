import React, {useContext, useState} from 'react';
import {AuthCard} from "../AuthCard/AuthCard";
import {AuthInput} from "../AuthInput/AuthInput";
import {AuthContext} from "../../context/AuthContext";
import {useMutation} from "@tanstack/react-query";

export function AuthCardLogin() {
    const [usuario, setUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const {handleLogin} = useContext(AuthContext)
    const [erros, setErros] = useState()

    const mutation = useMutation({
        mutationFn: ({ usuario, senha }) => handleLogin({ usuario, senha }),
        onError: () => {
            setErros('Erro ao realizar cadastro');
        }
    });

    const fazerLogin = () => {
        mutation.mutate({ usuario, senha });
    };

    return (
        <AuthCard isLoading={mutation.isPending} titulo={"Bem Vindo"} btnText={"Login"} handleSubmit={fazerLogin} errors={erros}>
            <AuthInput
                type="text"
                placeholder="Digite seu usuário"
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
            />
            <AuthInput
                type="password"
                placeholder="Digite sua senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
            />
        </AuthCard>
    );
}