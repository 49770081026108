import React from 'react';
import {ConfigProvider, Steps} from 'antd';
import {FaAddressCard, FaUser} from "react-icons/fa6";
import {PiAddressBookFill} from "react-icons/pi";
import {MdMedicalServices} from "react-icons/md";
import {FaGlobeAmericas} from "react-icons/fa";


const items = [
    {
        title: 'Dados Pessoais',
        icon: <FaUser />,
    },
    {
        title: 'Endereço',
        icon: <PiAddressBookFill />,
    },
    {
        title: 'Dados Médicos',
        icon: <MdMedicalServices />,
    },
    {
        title: 'Contatos de Emergência',
        icon: <FaAddressCard />,
    },
    {
        title: 'Dados Demográficos',
        icon: <FaGlobeAmericas />,
    },
];

export function ProgressBar( {step} ) {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Steps: {
                        customIconFontSize:45,
                        titleLineHeight:45,
                        colorPrimary: "var(--laranja-principal)",
                        colorSplit: "var(--cinza-background)",
                        colorTextDisabled: "var(--cinza-background)",
                        lineWidth:3,
                    }
                },
            }}
        >
            <Steps current={step} initial={1} items={items} style={{padding: "30px 50px"}}/>
        </ConfigProvider>
    )
}
