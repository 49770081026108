import styles from "../DadosPessoaisAfiliado.module.css";

export function DadosPessoais( {dadosPessoais} ) {
    return (
        <div>
            <ItemLista campo={"E-mail"} texto={dadosPessoais.email}/>
            <ItemLista campo={"CPF"} texto={dadosPessoais.cpf}/>
            <ItemLista campo={"RG"} texto={dadosPessoais.rg}/>
            <ItemLista campo={"Data de Nascimento"} texto={dadosPessoais.dataNascimento}/>
            <ItemLista campo={"Telefone"} texto={dadosPessoais.telefone}/>
            <ItemLista campo={"Gênero"} texto={dadosPessoais.genero}/>
            <ItemLista campo={"Status"} texto={dadosPessoais.status}/>
        </div>
    )
}

function ItemLista({campo, texto}) {
    return (
        <div className={styles.itemContainer}>
            <div className={styles.tituloCampo}>
                {campo}
            </div>
            <div className={styles.textoCampo}>
                {texto}
            </div>
        </div>
    )
}