import React, {cloneElement, useState} from "react"
import {useDisclosure,} from '@chakra-ui/react'
import {CustomModal} from "../CustomModal/CustomModal";
import {Button} from "../../components/CustomButtons/Button/Button";
import {ImBin} from "react-icons/im";
import {SuccessModal} from "../SuccessModal/SuccessModal";
import {IoCloseCircleOutline} from "react-icons/io5";
import styles from "../CustomModal/CustomModal.module.css";
import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";

export function CustomApagarModal({button, apiService, text, sendTo}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenSuccess, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
    const navigate = useNavigate()
    const [erro, setErro] = useState([])

    const handleSuccessModalClose = () => {
        onCloseSuccess();
        navigate(sendTo)
    };

    const handleErro = (erro) => {
        if (Array.isArray(erro)) {
            const erros = erro.map(item => ({ campo: item.campo, mensagem: item.mensagem }));
            setErro(erros);
        } else {
            setErro([{ campo: null, mensagem: erro.mensagem }]);
        }
    }

    const mutation = useMutation({
        mutationFn: () => apiService(),
        onSuccess: () => {
            onClose()
            onOpenSuccess()
        },
        onError: (error) => {
            if (error.response.data) handleErro(error.response.data);
        }
    })

    const handleSubmit = async () => {
        mutation.mutate();
    }

  
    const footer = (
        <>
            <Button
                isLoading={mutation.isPending}
                text={"Fechar"}
                icon = {<IoCloseCircleOutline size={22} />}
                backgroundColor={"var(--cinza-secundario)"}
                onClick={onClose}
            />
            <Button
                isLoading={mutation.isPending}
                text={"Excluir"}
                icon={<ImBin size={20} />}
                backgroundColor={"red"}
                onClick={handleSubmit}
            />
        </>
    )

 

    return (
        <>
            {cloneElement(button, { onClick: onOpen })}

            <CustomModal
                isOpen={isOpen}
                onClose={onClose}
                title={"Confirmação de Delete"}
                footer={footer}
                error={erro}
            >
                <p className={styles.textModal}>{text}</p>
            </CustomModal>

            <SuccessModal isOpen={isOpenSuccess} onClose={handleSuccessModalClose} text={"Deletado com sucesso!"} />
        </>
    )

}