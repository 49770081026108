import {Outlet} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ChakraProvider} from "@chakra-ui/react";
import {AuthProvider} from "./context/AuthContext";

export default function App () {

    const queryClient = new QueryClient()

    return (
        <AuthProvider>
            <ChakraProvider resetCSS={false}>
                <QueryClientProvider client={queryClient}>
                    <Outlet/>
                </QueryClientProvider>
            </ChakraProvider>
        </AuthProvider>


    )
}