import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {axiosInstance} from "../../services/axiosInstance";
import {fazerLogin} from "../../services/apiService/apiServices";

export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate =  useNavigate();

    useEffect(() => {
        const tokenData = JSON.parse(localStorage.getItem('token'));

        if (tokenData && new Date(tokenData.expirationDate) > new Date()) {
            setAuthenticated(true);
            axiosInstance.defaults.headers.Authorization = `Bearer ${tokenData.token}`;
        } else {
            handleLogout();
        }

        setLoading(false);
    }, []);

    async function handleLogin(formData) {
        const response = await fazerLogin(formData)

        if (response.status === 200) {
            const token = response.data.token;
            const expirationDate = response.data.expirationDate;
            localStorage.setItem('token', JSON.stringify({ token, expirationDate }));
            axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
        }

        setAuthenticated(true);
        navigate('/');
    }

    function handleLogout() {
        setAuthenticated(false);
        axiosInstance.defaults.headers.Authorization = undefined
        localStorage.removeItem('token');
        navigate('/auth/login');
    }

    return { authenticated, loading, handleLogin, handleLogout };
}