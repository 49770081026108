import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {cadastrarDadosDemograficosAfiliado} from "../../../services/apiService/cadastroAfiliadoService";
import {CadastroAfiliadoForm} from "../../../components/CadastroAfiliadoForm/CadastroAfiliadoForm";
import {DadosDemograficosForm} from "../../../components/AfiliadoForms/DadosDemograficosForm/DadosDemograficosForm";
import {useMutation} from "@tanstack/react-query";

export function CadastroDadosDemograficos() {
    const { id } = useParams();
    const [formData, setFormData] = useState();
    const navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: ({formData}) => cadastrarDadosDemograficosAfiliado(formData, id),
        onSuccess: () => {
            navigate(`/afiliados/${id}`);
        },
        onError: (error, { handleErro }) => {
            if (error.response.data) handleErro(error.response.data);
        }
    });

    const handleSubmit = (event, isFinalizar, handleErro) => {
        event.preventDefault();
        mutation.mutate({ formData, handleErro });
    };

    return (
        <CadastroAfiliadoForm isLoading={mutation.isPending} handleSubmit={handleSubmit} step={5} title={"Cadastro - Dados Demográficos"} >
            <DadosDemograficosForm setFormDataChange={(novoFormData) => setFormData(novoFormData)}/>
        </CadastroAfiliadoForm>
    )
}