import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import styles from "./CustomModal.module.css";
import {IoIosCloseCircleOutline} from "react-icons/io";

export function CustomModal( {isOpen, onClose,  children, title, footer, error, size} ) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={size ? size : 'xl'} >
            <ModalOverlay/>
            <ModalContent className={styles.modalContent}>
                <ModalHeader className={styles.modalHeader}>{title}</ModalHeader>
                <ModalCloseButton m={3} className={styles.modalCloseButton}>
                    <IoIosCloseCircleOutline size={26}/>
                </ModalCloseButton>
                <ModalBody className={styles.modalBody}>
                    <div className={styles.formContainer}>
                        {children}
                    </div>
                    {error && error.map(({mensagem}, index) =>
                        <p className={styles.modalError} key={index}>{mensagem}</p>
                    )}
                </ModalBody>
                {footer && <ModalFooter className={styles.modalFooter}>
                    {footer}
                </ModalFooter>}
            </ModalContent>
        </Modal>
    )
}
