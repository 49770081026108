import React, {useContext} from 'react';
import styles from '../../components/DocumentCardMinus/DocumentCardMinus.module.css'
import {MdOutlineFileDownload} from "react-icons/md";
import {AfiliadoContext} from "../../pages/DetalheAfiliado/DetalheAfiliado";
import {FiTrash} from "react-icons/fi";
import {Button} from "../CustomButtons/Button/Button";
import {CustomApagarModal} from "../../modals/CustomApagarModal/CustomApagarModal";
import {deletarDocumentoAfiliado} from "../../services/apiService/editarAfiliadoService";

export default function DocumentCardMinus({ key, idDocumento, nomeArquivo, formatoArquivo, tamanhoArquivo, linkDownload, corBackground }) {
    const { id, edit} = useContext(AfiliadoContext)

    const customModalApagar = (
        <CustomApagarModal
            button={<Button icon={<FiTrash size={25}/>} backgroundColor={"#ff2626"}/>}
            apiService={() => deletarDocumentoAfiliado(id, idDocumento)}
            text={"Deseja apagar esse documento?"}
            sendTo={`/afiliados/${id}`}
        />

    )

    return (
        <div key={key} className={styles.card} style={{backgroundColor: corBackground}}>
            <div className={`${styles.campo} ${styles.campoNome}`}>
                <p className={styles.tituloCampo}>Nome </p>
                <h4 className={styles.dadosCampo}>{nomeArquivo}</h4>
            </div>
            <div className={styles.campo}>
                <p className={styles.tituloCampo}>Formato </p>
                <h4 className={styles.dadosCampo}>{formatoArquivo}</h4>
            </div>
            <div className={styles.campo}>
                <p className={styles.tituloCampo}>Tamanho </p>
                <h4 className={styles.dadosCampo}>{tamanhoArquivo}</h4>
            </div>
            {
                edit ? customModalApagar : (
                    <a href={linkDownload} target={"_blank"} rel="noreferrer" className={styles.downloadButton}>
                        <MdOutlineFileDownload size={22}/>
                    </a>
                )
            }
        </div>
    );
};
